import {
    MenuAction,
    UserInput,
    userInputsWeights,
} from '../../../../../store/types/AutoAttendant';
import {FlowElements, FlowNode, NodeType} from './types';
import {v4 as id} from 'uuid';
import {Colors} from '../../../../../styles/Colors';
import {MenuActionFormType} from '../../../AutoAttendantsMenus/ActionsForm.utils';
import {EditAutoAttendantsMenuForm} from '../../../../../views/AutoAttendants/AutoAttendantsMenuDetails';
import {IntervalStatus} from '../../../../IntervalSelect/IntervalSelect';

const createNode = (
    type: 'menuNode' | 'actionNode' | 'startNode' | 'addNode' | 'hoursNode',
    data: NodeType,
) => {
    return {
        id: id(),
        type,
        data,
        position: {x: 0, y: 0},
    };
};

const createLink = (source: string, target: string, sourceHandle?: string) => {
    return {
        id: id(),
        type: 'connector',
        source,
        target,
        style: {
            stroke: Colors.Secondary12,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeMiterlimit: 1,
        },
        sourceHandle,
    };
};

export const generateFlowBasedOnTransitions = (
    menus: EditAutoAttendantsMenuForm[],
    visibleMenus: Map<number, boolean>,
    generateDiagram?: () => void,
    onPressTransitionNode?: (action: {
        transition?: MenuActionFormType;
        menu: EditAutoAttendantsMenuForm;
    }) => void,
    onPressMenuEdit?: (menu?: EditAutoAttendantsMenuForm) => void,
) => {
    const nodes: FlowElements = [];
    const existedMenus = new Map<number, number>();

    const getMenuObjects = (
        menu: EditAutoAttendantsMenuForm,
        menuNode: FlowNode,
    ) => {
        const transitions = menu.actions;

        transitions
            ?.sort((v, w) =>
                userInputsWeights[v.userInput!] <
                userInputsWeights[w.userInput!]
                    ? -1
                    : 1,
            )
            ?.filter(
                (v) =>
                    !(
                        v.userInput === UserInput.NotActive &&
                        menu?.intervals.activity === IntervalStatus.Always
                    ),
            )
            .forEach((transition) => {
                if (transition.action === MenuAction.Menu) {
                    const newMenu = menus.find(
                        (v) => v.menuId === transition.menu?.i_menu,
                    );

                    if (newMenu) {
                        if (newMenu.name === 'ROOT') {
                            const newMenuNode = createNode('menuNode', {
                                transition: transition,
                                menu: newMenu,
                                parentMenu: menu,
                                onPressTransitionNode,
                                onPressMenuEdit,
                            });
                            const link = createLink(
                                menuNode.id,
                                newMenuNode.id,
                            );

                            nodes.push(newMenuNode, link);
                        } else {
                            const newMenuNode = createNode('menuNode', {
                                transition: transition,
                                menu: newMenu,
                                parentMenu: menu,
                                menuOpened: visibleMenus.get(newMenu.menuId),
                                toggleMenu: () => {
                                    visibleMenus.set(
                                        newMenu.menuId,
                                        !visibleMenus.get(newMenu.menuId),
                                    );
                                    generateDiagram?.();
                                },
                                menuExisting: existedMenus.has(newMenu.menuId),
                                onPressTransitionNode,
                                onPressMenuEdit,
                            });
                            const link = createLink(
                                menuNode.id,
                                newMenuNode.id,
                            );

                            nodes.push(newMenuNode, link);

                            if (
                                !existedMenus.has(newMenu.menuId) &&
                                visibleMenus.get(newMenu.menuId)
                            ) {

                                existedMenus.set(
                                    newMenu.menuId,
                                    newMenu.menuId,
                                );

                                const afterHoursNode = createNode('hoursNode', {
                                    transition:
                                    transition,
                                    menu: newMenu,
                                });

                                const afterHoursLink = createLink(
                                    newMenuNode.id,
                                    afterHoursNode.id,
                                );

                                getMenuObjects(newMenu, afterHoursNode);

                                if ((newMenu.actions?.length || 0) < 15) {
                                    const addNode = createNode('addNode', {
                                        transition: transition,
                                        parentMenu: newMenu,
                                        onPressTransitionNode,
                                    });
                                    const addNodeLink = createLink(
                                        afterHoursNode.id,
                                        addNode.id,
                                    );

                                    nodes.push(addNode, addNodeLink);
                                }

                                nodes.push(afterHoursNode, afterHoursLink);
                            }
                        }
                    }
                } else if (
                    !(
                        transition.userInput === UserInput.NotActive &&
                        menu.intervals.activity === IntervalStatus.Always
                    )
                ) {
                    const newNode = createNode('actionNode', {
                        transition: transition,
                        parentMenu: menu,
                        onPressTransitionNode,
                    });
                    nodes.push(newNode);

                    if (transition.userInput === UserInput.NotActive) {
                        const link = createLink(menuNode.id, newNode.id, 'c');
                        nodes.push(link);
                    } else {
                        const link = createLink(menuNode.id, newNode.id);
                        nodes.push(link);
                    }
                }
            });
    };

    const rootMenu = menus.find((v) => v.name === 'ROOT');

    if (rootMenu) {
        const startNode = createNode('startNode', {});
        const rootMenuNode = createNode('menuNode', {
            menu: rootMenu,
            onPressMenuEdit,
        });
        const link = createLink(startNode.id, rootMenuNode.id);

        nodes.push(startNode, rootMenuNode, link);

        const inactiveTransition = rootMenu.actions?.find(
            (v) => v.userInput === UserInput.NotActive,
        );

        if (
            rootMenu.intervals.activity !== IntervalStatus.Always &&
            inactiveTransition
        ) {
            const afterHoursNode = createNode('hoursNode', {
                transition: inactiveTransition,
                menu: rootMenu,
            });

            const afterHoursLink = createLink(
                rootMenuNode.id,
                afterHoursNode.id,
                'c',
            );

            nodes.push(afterHoursNode, afterHoursLink);

            getMenuObjects(rootMenu, afterHoursNode);

            if ((rootMenu.actions?.length || 0) < 15) {
                const addNode = createNode('addNode', {
                    parentMenu: rootMenu,
                    onPressTransitionNode,
                });

                const addNodeLink = createLink(afterHoursNode.id, addNode.id);

                nodes.push(addNode, addNodeLink);
            }
        } else {
            getMenuObjects(rootMenu, rootMenuNode);

            if ((rootMenu.actions?.length || 0) < 15) {
                const addNode = createNode('addNode', {
                    parentMenu: rootMenu,
                    onPressTransitionNode,
                });

                const addNodeLink = createLink(rootMenuNode.id, addNode.id);
                nodes.push(addNode, addNodeLink);
            }
        }
    }

    return nodes;
};
