import { makeStyles } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { Colors } from "../../../../styles/Colors";
import { TFunction, useTranslation } from 'react-i18next';
import CreationButton from "../../../CreationButton/CreationButton";
import { ReduxState } from "../../../../store/types/store";
import { useSelector } from "react-redux";
import RowExpander from "../../../Expander/RowExpander";
import DataGrid from "../../../DataGrid/DataGrid";
import { Column } from "react-table";
import CustomizedIconButton from "../../../IconButton/IconButton";
import { Delete, Edit } from "@material-ui/icons";
import { PeriodInfo } from "../../../../store/types/AccountFollowMeSettings";
import { CPConditionInfo } from "../../../../store/types/CallScreening";
import CpNumbersCell from "../../../Calls/CpNumbersCell";
import classNames from "classnames";
import { usePermissionContext } from "../../../../hooks/usePermissions";
import { Permission, PermissionType } from "../../../../store/types/Permission";
import PermissionLinkCell from "../../../DataTable/PermissionLinkCell";
import { TimeFiltersPageFilterType } from "./NewTimeFilterDialog.utils";
import NewTimeFilterDialog from "./NewTimeFilterDialog";
import OverflowTooltip from "../../../OverflowTooltip/OverflowTooltip";
import NewIntervalDialog from "./NewIntervalDialog";
import { stringifyInterval } from "./NewIntervalDialog.utils";

interface TimeFiltersTabProps {
    pageFilterType: TimeFiltersPageFilterType;
    onDelete: (mode: CPConditionInfo) => void;
    onDeleteInterval?: (interval: PeriodInfo, cpCondition: CPConditionInfo) => void;
}

const useStyles = makeStyles(() => ({
    pageContainer: {
        background: Colors.White,
        width: 'calc(100% - 48px)',
        paddingLeft: 32,
        paddingRight: 16,
        paddingTop: 29,
        paddingBottom: 0
    },
    smallPadding: {
        paddingTop: 13,
    },
    addFilterButton: {
        marginBottom: 16
    },
    timeIntervalCollapseContainer: {
        background: Colors.White
    },
    tableContainer: {
        background: Colors.White,
        width: 752,
        border: 'none !important',

        '& table': {
            background: 'transparent',
            border: 'none !important',
            overflowX: 'hidden',

            '& thead': {
                background: 'transparent',
                border: 'none',
            },
            
            '& tbody': {
                background: 'transparent',
                border: 'none',

                '& tr': {
                    background: 'transparent',
                    border: 'none',
                }
            },
                
            '& .MuiTableCell-root': {
                paddingLeft: 8,
            },
        }
    },
    tableInsideRowExpander: {
        paddingLeft: 40,
        marginTop: 22,
        
        '& table': {  
            '& .MuiTableCell-root': {
                paddingLeft: 0,
            },
        }
    },
    addIntervalButton: {
        marginTop: 8,
        marginLeft: 32
    },
    addNumberButton: {
        marginTop: 25,
        marginBottom: 15
    },
    numbersContainer: {
        maxHeight: 50,
        whiteSpace: 'normal',
        width: 'calc(100% - 24px)',
        
        '& > :first-child': {
            maxHeight: 50,
            whiteSpace: 'normal',
        },
    }
}));

export const generateColumnsTimeIntervals = (
    t: TFunction<string>,
    permission: PermissionType,
    onEdit?: (object: PeriodInfo, rowId: string) => void, 
    onDelete?: (object: PeriodInfo, rowId: string) => void,
    ampm?: boolean
): Column<PeriodInfo>[] => {

    return [
        {
            Header: t<string>('screens:callSettings.timeInterval'),
            accessor: 'start_time',
            width: 4,
            Cell: function Cell(params) {
                const interval = stringifyInterval(t, params.row.original, ampm);
                return (
                    <PermissionLinkCell
                        text={interval}
                        onClick={() => {
                            (permission === PermissionType.Visible) && onEdit?.(params.row.original, params.row.id);
                        }}
                        permissions={Permission.Calls.Settings.CallScreening.value}
                    />
                );
            },
        },
        {
            Header: '',
            accessor: 'end_time',
            width: 1,
            Cell: function Cell(params) {
                const original = params.row.original;
                return (<>
                    <CustomizedIconButton
                        onClick={() => {
                            onEdit?.(original, params.row.id);
                        }}
                        dataTestId="edit-time-interval-button"
                        dataQa="edit-time-interval-button"
                        tooltipText={t<string>('common:edit')}
                    >
                        <Edit htmlColor={Colors.ListIconColor}/>
                    </CustomizedIconButton>
                    <CustomizedIconButton
                        onClick={() => {
                            onDelete?.(original, params.row.id);
                        }}
                        dataTestId="remove-time-interval-button"
                        dataQa="remove-time-interval-button"
                        tooltipText={t<string>('common:delete')}
                    >
                        <Delete htmlColor={Colors.ListIconColor}/>
                    </CustomizedIconButton>
                </>);
            },
        }
    ];
};

const generateColumnsFromTo = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    permission: PermissionType,
    isInUse: (filter: CPConditionInfo) => boolean,
    onEdit?: (object: CPConditionInfo) => void, 
    onDelete?: (object: CPConditionInfo) => void, 
): Column<CPConditionInfo>[] => {

    return [
        {
            Header: t<string>('common:name'),
            accessor: 'name',
            width: 2,
            Cell: function Cell(params) {
                const name = params.row.original.name || '';
                return (
                    <PermissionLinkCell
                        text={name}
                        onClick={() => {
                            (permission === PermissionType.Visible ) && onEdit?.(params.row.original);
                        }}
                        permissions={Permission.Calls.Settings.CallScreening.value}
                    />
                );
            },
        },
        {
            Header: t<string>('screens:callSettings.numberOrExtension'),
            accessor: 'i_customer',
            width: 2,
            Cell: function Cell(params) {
                const numbersCell = (<CpNumbersCell cpNumbers={params.row.original} />);
                return (
                    <div className={classes.numbersContainer}>
                        <OverflowTooltip
                            tooltip={numbersCell}
                            text={numbersCell}
                            copy={false}
                        />
                    </div>
                );
            },
        },
        {
            Header: '',
            accessor: 'i_cp_condition',
            width: 1,
            Cell: function Cell(params) {
                const original = params.row.original;
                return (<>
                    <CustomizedIconButton
                        onClick={() => {
                            onEdit?.(original);
                        }}
                        dataTestId="edit-number-button"
                        dataQa="edit-number-button"
                        tooltipText={t<string>('common:edit')}
                    >
                        <Edit htmlColor={Colors.ListIconColor}/>
                    </CustomizedIconButton>
                    {!isInUse(original) && (
                        <CustomizedIconButton
                            onClick={() => {
                                onDelete?.(original);
                            }}
                            dataTestId="remove-number-button"
                            dataQa="remove-number-button"
                            tooltipText={t<string>('common:delete')}
                        >
                            <Delete htmlColor={Colors.ListIconColor}/>
                        </CustomizedIconButton>
                    )}
                </>);
            },
        }
    ];
};

const TimeFiltersTab: React.VFC<TimeFiltersTabProps> = ({
    pageFilterType,
    onDelete,
    onDeleteInterval
}) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const permission = usePermissionContext();

    const policies = useSelector(
        (state: ReduxState) => state.extensions?.callProcessingPolicyList || [],
    );
    
    const { cpConditionsList } = useSelector(
        (state: ReduxState) => state.callSettings,
    );
    
    const [showTimeFilterDialog, setShowTimeFilterDialog] = useState<{
        isOpen: boolean;
        editObject?: CPConditionInfo;
        filterType: TimeFiltersPageFilterType;
    }>({
        isOpen: false,
        filterType: TimeFiltersPageFilterType.TimeWindow
    });
    
    const [showIntervalDialog, setShowIntervalDialog] = useState<{
        isOpen: boolean;
        cpCondition: CPConditionInfo;
        editObject?: PeriodInfo;
    }>({
        isOpen: false,
        cpCondition: {}
    });
    
    const outTimeFormat = useSelector<ReduxState, string | undefined>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_time_format,
    );

    const ampm = outTimeFormat?.includes('AM');

    const isFilterInUse = (filter: CPConditionInfo) => {
        if(!policies?.length) return false;
        for(const policy of policies) {
            if(!policy?.rule_list?.length) continue;
            for(const rule of policy.rule_list) {
                const usedIds = [
                    rule.from_number_i_cp_condition || 0,
                    rule.to_number_i_cp_condition || 0,
                    rule.time_window_i_cp_condition || 0
                ];
                if(usedIds.includes(filter.i_cp_condition || -1)) {
                    return true;
                }
            }
        }
        return false;
    };
    
    const columnsTimeIntervals = generateColumnsTimeIntervals(
        t,
        permission,
        (object: PeriodInfo, rowId: string) => {
            const indexIds = rowId.split('_');
            const index = parseInt(indexIds[1] + '');
            const cpCondition = cpConditionsList?.cp_condition_list.find(e => e.i_cp_condition === index);
            if(cpCondition) {
                setShowIntervalDialog({
                    isOpen: true,
                    editObject: object,
                    cpCondition: cpCondition
                });
            }
        },
        (interval: PeriodInfo, rowId: string) => {
            const indexIds = rowId.split('_');
            const index = parseInt(indexIds[1] + '');
            const cpCondition = cpConditionsList?.cp_condition_list.find(e => e.i_cp_condition === index);
            if(cpCondition) {
                onDeleteInterval?.(interval, cpCondition);
            }
        },
        ampm
    );

    const columnsFromTo = generateColumnsFromTo(
        t,
        classes,
        permission,
        isFilterInUse,
        (object: CPConditionInfo) => {
            const type = object.type?.toString() || TimeFiltersPageFilterType.FromNumber.toString();
            setShowTimeFilterDialog({
                isOpen: true,
                editObject: object,
                filterType: type as TimeFiltersPageFilterType
            });
        },
        onDelete
    );
    
    const conditions = useMemo(() => {
        return (
            cpConditionsList?.cp_condition_list?.filter((v) => v.type === pageFilterType.toString()) ||
            []
        );
    }, [cpConditionsList]);
    
    const onClickAddIntervalHandler = (condition: CPConditionInfo) => {
        setShowIntervalDialog({
            isOpen: true,
            editObject: undefined,
            cpCondition: condition
        });
    };

    return (
        <div className={classNames(classes.pageContainer, (pageFilterType !== 'TimeWindow') && classes.smallPadding)}
        >
            {pageFilterType === TimeFiltersPageFilterType.TimeWindow ? (<CreationButton
                data-qa="add-filter-button"
                onClick={() => {
                    setShowTimeFilterDialog({
                        isOpen: true,
                        editObject: undefined,
                        filterType: TimeFiltersPageFilterType.TimeWindow
                    });
                }}
                className={classes.addFilterButton}
                title={t('screens:callSettings.addFilter')}
            />) : (<></>)}
            {pageFilterType === TimeFiltersPageFilterType.TimeWindow ? (<div>
                    {conditions.map((condition, indx) => {
                        return (
                            <RowExpander key={'time_window' + indx}
                                title={condition.name}
                                isOpenedOnInit={false}
                                badgeCount={condition.time_window?.definition_list?.length || 0}
                                className={classes.timeIntervalCollapseContainer}
                                showUnderline={indx !== (conditions.length - 1)}
                                onDelete={() => {
                                    onDelete(condition);
                                }}
                                hideDelete={isFilterInUse(condition)}
                                onEdit={() => {
                                    setShowTimeFilterDialog({
                                        isOpen: true,
                                        editObject: condition,
                                        filterType: pageFilterType
                                    });
                                }}
                            >
                                <DataGrid<PeriodInfo>
                                    classes={{
                                        tableContainer: classNames(classes.tableContainer, classes.tableInsideRowExpander),
                                    }}
                                    columns={columnsTimeIntervals}
                                    data={condition.time_window?.definition_list || []}
                                    rowCount={condition.time_window?.definition_list?.length || 0}
                                    pageSizeOptions={[condition.time_window?.definition_list?.length || 0]}
                                    initialPageSize={Number.MAX_VALUE}
                                    centeredRows
                                    hideFooter
                                    getItemId={(r, i) => 'cp_' + condition.i_cp_condition + '_interval_' + i}
                                />
                                <CreationButton
                                    data-qa="add-interval-button"
                                    className={classes.addIntervalButton}
                                    onClick={() => onClickAddIntervalHandler(condition)}
                                    title={t('screens:callSettings.addInterval')}
                                />
                            </RowExpander>
                        );
                    })}
                </div>) : (
                    <DataGrid<CPConditionInfo>
                        classes={{
                            tableContainer: classes.tableContainer,
                        }}
                        columns={columnsFromTo}
                        data={conditions}
                        rowCount={conditions.length || 0}
                        pageSizeOptions={[conditions.length || 0]}
                        initialPageSize={Number.MAX_VALUE}
                        centeredRows
                        hideFooter
                        getItemId={(r) => (r.i_cp_condition || 0) + ''}
                    />
                )
            }
            {pageFilterType !== TimeFiltersPageFilterType.TimeWindow ? (<CreationButton
                data-qa="add-filter-button"
                onClick={() => {
                    setShowTimeFilterDialog({
                        isOpen: true,
                        editObject: undefined,
                        filterType: pageFilterType
                    });
                }}
                className={classes.addNumberButton}
                title={t('screens:callSettings.addNumber')}
            />) : (<></>)}
            <NewTimeFilterDialog
                isOpen={showTimeFilterDialog.isOpen}
                clickOnClose={() => {
                    setShowTimeFilterDialog({
                        isOpen: false,
                        editObject: undefined,
                        filterType: TimeFiltersPageFilterType.TimeWindow
                    });
                }}
                editObject={showTimeFilterDialog.editObject}
                filterType={showTimeFilterDialog.filterType}
            />
            <NewIntervalDialog
                isOpen={showIntervalDialog.isOpen}
                clickOnClose={() => {
                    setShowIntervalDialog({
                        isOpen: false,
                        editObject: undefined,
                        cpCondition: {}
                    });
                }}
                editObject={showIntervalDialog.editObject}
                cpCondition={showIntervalDialog.cpCondition}
            />
        </div>
    )
}

export default TimeFiltersTab;