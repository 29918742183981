import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as NoPermission } from '../../assets/no-permission.svg';
import CustomizedButton from '../../components/Button/Button';
import { Colors } from '../../styles/Colors';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.SmokeBackground,
    },
    title: {
        fontSize: 32,
        fontWeight: 500,
        color: Colors.Text,
    },
    description: {
        fontWeight: 400,
        color: Colors.Gray5,
    },
    button: {
        marginTop: 30,
        marginBottom: 60,
        width: 210,
        '& .MuiButton-label': {
            color: Colors.White,
            padding: '0 25px',
        },
    },
}));

const AccessDenied: React.VFC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    
    return (
        <div className={classes.root}>
            <NoPermission />
            <h2 className={classes.title}>{t('common:accessDenied')}</h2>
            <span className={classes.description}>
                {t('common:noPermissionToPage')}
            </span>

            <CustomizedButton
                className={classes.button}
                primary
                onClick={history.goBack}
            >
                {t('common:goBack')}
            </CustomizedButton>
        </div>
    );
};

export default AccessDenied;
