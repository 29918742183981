import { createAsyncAction } from 'typesafe-actions';
import * as payloads from './payloads';
import { APIErrorInterface } from '../../types';

export const getPhoneBookList = createAsyncAction(
    'GET_PHONE_BOOK_LIST',
    'GET_PHONE_BOOK_LIST_SUCCESSED',
    'GET_PHONE_BOOK_LIST_FAILED',
)<
    payloads.PhoneBookListRequestPayload,
    payloads.PhoneBookListResponsePayload,
    Error
>();

export const addNewPhoneBook = createAsyncAction(
    'ADD_PHONE_BOOK',
    'ADD_PHONE_BOOK_SUCCESSED',
    'ADD_PHONE_BOOK_FAILED',
)<payloads.PhoneBookAddNewtRequestPayload, payloads.MaxNumberResponsePayload, APIErrorInterface>();

export const deletePhoneBook = createAsyncAction(
    'DELETE_PHONE_BOOK',
    'DELETE_PHONE_BOOK_SUCCESSED',
    'DELETE_PHONE_BOOK_FAILED',
)<payloads.PhoneBookDeleteRequestPayload, undefined, APIErrorInterface>();

export const getMaxAbbreviated = createAsyncAction(
    'GET_MAX_ABBREVIATED',
    'GET_MAX_ABBREVIATED_SUCCESSED',
    'GET_MAX_ABBREVIATED_FAILED',
)<undefined, payloads.MaxNumberResponsePayload, undefined>();
