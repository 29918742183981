import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../../..';
import { APIErrorInterface } from '../../../types';

type ExtensionDetailsType = {
    extensionTab?: {
        isLoading?: boolean;
    };
    callSettingsTab?: {
        isLoading?: boolean;
    };
    callScreeningTab?: {
        isLoading?: boolean;
    };
    planTab?: {
        isLoading?: boolean;
    };
    deviceTab?: {
        isLoading?: boolean;
    };
    callBarringTab?: {
        isLoading?: boolean;
    };
    callForwardingTab?: {
        isLoading?: boolean;
    };
};

export interface ExtensionTabReducerState {
    isFormDataLoading?: boolean;
    extensionDetailsTabs?: ExtensionDetailsType;
    apiError?: APIErrorInterface;
}

export const initialState: ExtensionTabReducerState = {
    isFormDataLoading: false,
};

export type ExtensionTabReducerActions = ActionType<typeof actions>;

export const extensionTabsReducer = createReducer<
    ExtensionTabReducerState,
    ExtensionTabReducerActions
>(initialState)
    .handleAction(actions.getExtensionBasicDetailsData.request, (state) => {
        return {
            ...state,
            isFormDataLoading: true,
            apiError: undefined,
            extensionDetailsTabs: {
                extensionTab: {
                    isLoading: true,
                },
                callSettingsTab: {
                    isLoading: true,
                },
                callScreeningTab: {
                    isLoading: true,
                },
                planTab: {
                    isLoading: true,
                },
                deviceTab: {
                    isLoading: true,
                },
                callBarringTab: {
                    isLoading: true,
                },
                callForwardingTab: {
                    isLoading: true,
                },
            },
        };
    })
    .handleAction(actions.getExtensionBasicDetailsData.failure, (state, action) => ({
        ...state,
        apiError: action.payload,
        extensionDetailsTabs: {
            extensionTab: {
                isLoading: false,
            },
            callSettingsTab: {
                isLoading: false,
            },
            callScreeningTab: {
                isLoading: false,
            },
            planTab: {
                isLoading: false,
            },
            deviceTab: {
                isLoading: false,
            },
            callBarringTab: {
                isLoading: false,
            },
            callForwardingTab: {
                isLoading: false,
            },
        },
    }))
    .handleAction(actions.getExtensionTabData.success, (state) => ({
        ...state,
        apiError: undefined,
        extensionDetailsTabs: {
            ...state.extensionDetailsTabs,
            extensionTab: {
                isLoading: false,
            },
        },
    }))
    .handleAction(actions.getCallSettingsTabData.success, (state) => ({
        ...state,
        apiError: undefined,
        extensionDetailsTabs: {
            ...state.extensionDetailsTabs,
            callSettingsTab: {
                isLoading: false,
            },
        },
    }))
    .handleAction(actions.getPlanTabData.success, (state) => ({
        ...state,
        apiError: undefined,
        extensionDetailsTabs: {
            ...state.extensionDetailsTabs,
            planTab: {
                isLoading: false,
            },
        },
    }))
    .handleAction(actions.getDeviceTabData.success, (state) => ({
        ...state,
        apiError: undefined,
        extensionDetailsTabs: {
            ...state.extensionDetailsTabs,
            deviceTab: {
                isLoading: false,
            },
        },
    }))
    .handleAction(actions.getCallForwardingTabData.request, (state) => ({
        ...state,
        apiError: undefined,
        extensionDetailsTabs: {
            ...state.extensionDetailsTabs,
            callForwardingTab: {
                isLoading: true,
            },
        },
    }))
    .handleAction(actions.getCallForwardingTabData.success, (state) => ({
        ...state,
        apiError: undefined,
        extensionDetailsTabs: {
            ...state.extensionDetailsTabs,
            callForwardingTab: {
                isLoading: false,
            },
        },
    }))
    .handleAction(actions.getCallScreeningTabData.request, (state) => ({
        ...state,
        apiError: undefined,
        extensionDetailsTabs: {
            ...state.extensionDetailsTabs,
            callScreeningTab: {
                isLoading: true,
            },
        },
    }))
    .handleAction(actions.getCallScreeningTabData.success, (state) => ({
        ...state,
        apiError: undefined,
        extensionDetailsTabs: {
            ...state.extensionDetailsTabs,
            callScreeningTab: {
                isLoading: false,
            },
        },
    }));

export default extensionTabsReducer;
