import React, {useEffect, useState} from 'react';
import TransactionFilters, {
    TransactionFilterDateModes,
    TransactionFiltersFormType,
} from '../../../components/TransactionFilters/TransactionFilters';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import dayjs from '../../../services/customDayJs';
import {
    generateColumns,
    getFilterPayload,
    TransactionTableRow,
    useStyles,
} from './TransactionList.utils';
import {actions} from '../../../store';
import {transactionEntityTransformer} from '../../../utils/transformers';
import {useTransactionTabdata} from '../../../hooks/useTransactionTabdata';
import toast from 'react-hot-toast';
import TransactionDialog from '../../../components/TransactionDialog/TransactionDialog';
import {getDefaultDatePreferences} from './utils/DateUtils';
import {generateTabs} from '../../../utils/generateTabs';
import {Permission} from '../../../store/types/Permission';
import DetailsWrapper from '../../../components/DetailsWraper/DetailsWrapper';
import {CloudDownload} from '@material-ui/icons';
import {Colors} from '../../../styles/Colors';
import TransactionChart from './TransactionChart';
import CustomizedTooltip from '../../../components/Tooltip/Tooltip';
import Button from '@material-ui/core/Button';
import {useRawPermissions} from '../../../hooks/usePermissions';
import {ReduxState} from "../../../store/types";

const TransactionsList = () => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const {
        userDateTimeFormat,
        timezoneOffset,
        transactionsList,
        currency,
        isLoading,
    } = useTransactionTabdata();

    const permissions = useRawPermissions();

    const [items, setItems] = useState<{
        data: TransactionTableRow[];
        total: number;
    }>({data: [], total: 0});

    const [isDownloadingGoingOn, setIsDownloadingGoingOn] = useState<boolean[]>(
        [],
    );

    const [filters, setFilters] = useState<TransactionFiltersFormType>({
        from: getDefaultDatePreferences(new Date()).currentMonthStartDate,
        till: getDefaultDatePreferences(new Date()).currentMonthEndDate,
        dateMode: TransactionFilterDateModes.ThisMonth
    });

    const [details, setDetails] = useState<{
        serviceName: string;
        isOpen: boolean;
        index?: number;
        i_service?: number;
        i_service_type?: number;
        ratio?: number;
        unit?: string;
    }>({
        isOpen: false,
        serviceName: 'Unknown',
    });

    const {paymentSuccess} = useSelector(
        (state: ReduxState) => state.billing,
    );

    const handleDetails = (
        serviceName: string,
        i_service: number,
        i_service_type: number,
        index: number,
        ratio?: number,
        unit?: string,
    ) => {
        setDetails((prevState) => ({
            serviceName: serviceName,
            isOpen: !prevState.isOpen,
            index,
            i_service,
            i_service_type,
            ratio,
            unit: unit,
            initData: undefined,
            filters: undefined
        }));
    };

    const toggleDetailsVisibility = () => {
        setDetails((prevState) => ({
            ...prevState,
            isOpen: !prevState.isOpen,
        }));

        setTimeout(() => {
            setDetails((prevState) => ({
                ...prevState,
                index: undefined,
                i_service: undefined,
                i_service_type: undefined,
                ratio: undefined,
                unit: undefined,
                initData: undefined,
                filters: undefined
            }));
        }, 300);
    };

    const fetchData = () => {
        dispatch(
            actions.getTransactionList.request({
                ...getFilterPayload(filters, timezoneOffset),
            }),
        );
    };

    const toggleTooltipVisibility = (index: number) => {
        const newDownloadings = [...isDownloadingGoingOn];
        newDownloadings[index] = false;
        setIsDownloadingGoingOn(newDownloadings);
    };

    const downloadFile = (
        filters: TransactionFiltersFormType,
        timezoneOffset: number,
        index = 0,
    ) => {
        const newDownloading = [...isDownloadingGoingOn];
        newDownloading[index] = true;
        setIsDownloadingGoingOn(newDownloading);
        toast(t<string>('common:downloadWillStartShortly'));
        dispatch(
            actions.getTransactionCsvFile.request({
                ...getFilterPayload(filters, timezoneOffset),
                i_service: filters?.i_service,
                fileName: 'transactions-' + dayjs().unix(),
                callback: () => toggleTooltipVisibility(index),
            }),
        );
    };

    const prepareTotalObj = (data: TransactionTableRow[], isSpendings: boolean): TransactionTableRow => {
        const totals = data.filter((e) => e.isSpending === isSpendings).reduce((a, b) => a + (b.total || 0), 0);
        const amount = data.filter((e) => e.isSpending === isSpendings).reduce((a, b) => a + (Math.abs(b.amount) || 0), 0);
        const totalObj: TransactionTableRow = {
            isSummary: true,
            total: totals,
            type: t('screens:myCompany.total'),
            charged: 0,
            amount: amount,
            id: 'total' + (isSpendings ? '1' : '2'),
            unit: '',
            unitShort: '',
            isSpending: isSpendings
        };
        totalObj.amount = Number(totalObj.amount.toFixed(2));
        return totalObj;
    }

    useEffect(() => {
        if (dayjs(new Date(filters.from?.replace(/-/g, '/') || '')).isValid() &&
            dayjs(new Date(filters.till?.replace(/-/g, '/') || '')).isValid()) {
            fetchData();
        }
    }, [filters]);

    useEffect(() => {
        if (transactionsList) {
            const {items} = transactionsList;

            const data = items.map(
                transactionEntityTransformer,
            ) as TransactionTableRow[];

            const summarySpending = prepareTotalObj(data, true);
            const summaryIncoming = prepareTotalObj(data, false);

            data.push(summarySpending);
            data.push(summaryIncoming);

            setItems({
                total: data.length || 0,
                data: data,
            });
        }
    }, [transactionsList]);

    const columnsSpending = generateColumns(t, true, currency, classes, handleDetails);
    const columnsIncoming = generateColumns(t, false, currency, classes, handleDetails);

    let spendingData = items.data.filter(e => e.isSpending);
    let incomingData = items.data.filter(e => !e.isSpending);
    spendingData = spendingData.length == 1 ? [] : spendingData;
    incomingData = incomingData.length == 1 ? [] : incomingData;

    const {tabNames, tabs} = generateTabs(
        [
            {
                title: t('screens:billing.charges'),
                permission:
                Permission.MyCompany.Billing.Transactions.value,
                tab: (
                    <TransactionChart
                        columns={columnsSpending}
                        inputdata={spendingData}
                        currency={currency}
                        dataQa={'transactions-table-1'}
                        isLoading={isLoading || false}
                        hideFooter={true}
                        message={t('screens:billing.noData')}
                        showNoDataMsg={true}
                    >
                    </TransactionChart>
                ),
                skipReadOnly: true
            },
            {
                title: t('screens:billing.paymentsAndCredits'),
                permission:
                Permission.MyCompany.Billing.Transactions.value,
                tab: (
                    <TransactionChart
                        columns={columnsIncoming}
                        inputdata={incomingData}
                        currency={currency}
                        dataQa={'transactions-table-2'}
                        isLoading={isLoading || false}
                        hideFooter={true}
                        showNoDataMsg={true}
                        message={t('screens:billing.noData')}
                    >
                    </TransactionChart>
                ),
                skipReadOnly: true
            }
        ],
        permissions
    );

    const DownloadIcon = (
        <CloudDownload
            htmlColor={Colors.Secondary2}
            style={{height: 22, width: 22, marginRight: 6, marginLeft: -3}}
        />
    );

    const e = document?.forms[0]?.parentNode as HTMLElement;
    if (e) {
        e.setAttribute('style', 'width: 0; height: 0;');
    }
    const d = document.getElementsByClassName('MuiBox-root');
    if (d?.length) {
        for (const d1 of d) {
            d1.setAttribute('style', 'padding-bottom: 0');
        }
    }

    return (
        <div className={classes.mainContainer} id="transactionList">
            <TransactionFilters
                id={'transaction-filters-container'}
                userDateTimeFormat={userDateTimeFormat}
                initData={filters}
                onChange={(values: TransactionFiltersFormType) => {
                    setFilters(values);
                }}
                onClick={() => {
                    if (dayjs(new Date(filters.from?.replace(/-/g, '/') || '')).isValid() &&
                        dayjs(new Date(filters.till?.replace(/-/g, '/') || '')).isValid()) {
                        fetchData();
                    }
                }}
                timeZoneOffset={timezoneOffset}
            />
            <DetailsWrapper
                tabs={tabs}
                tabsNames={tabNames}
                hideCancel
                callFlowOnFirstTab
                showActionButtons={false}
                saveEnable={false}
                preventIsOpen={false}
                defaultTabIndex={paymentSuccess ? 1 : 0}
                subTabMode={true}
                doNotControlForms
                customRightSideComponent={(
                    <div>
                        <CustomizedTooltip
                            title={t('tooltips:billing:downloadCsvButtonTooltip')}
                            copy={false}
                            disableHoverListener={false}
                        >
                            <Button
                                data-qa={'download-csv-button'}
                                data-testid={'download-csv-button'}
                                key='download-key'
                                type="button"
                                onClick={() => {
                                    downloadFile(filters, timezoneOffset || 0);
                                }}
                                className={classes.downloadButton}
                            >
                                {DownloadIcon}
                                {t('screens:billing:downloadCsvButtonLabel')}
                            </Button>
                        </CustomizedTooltip>
                    </div>
                )}
            >
            </DetailsWrapper>
            <TransactionDialog
                serviceName={details.serviceName}
                isOpen={details.isOpen}
                initData={filters}
                toggleVisibility={toggleDetailsVisibility}
                userDateTimeFormat={userDateTimeFormat}
                index={details.index}
                i_service={details.i_service}
                i_service_type={details.i_service_type}
                onClickCsvButton={downloadFile}
                currency={currency}
                initialPageSize={10}
                ratio={details.ratio}
                unit={details.unit}
                timezoneOffset={timezoneOffset}
            />
        </div>
    );
};

export default TransactionsList;
