import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import { Add } from '@material-ui/icons';
import classNames from 'classnames';
import { PermissionType } from '../../store/types/Permission';
import { usePermissionContext } from '../../hooks/usePermissions';
import { useTranslation } from 'react-i18next';
import CustomizedTooltip from '../Tooltip/Tooltip';

type CreationButtonProps = {
    onClick?: () => void;
    title?: string;
    className?: string;
    withPlus?: boolean;
    disabled?: boolean;
    customDisabledTooltipText?: string;
};

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: Colors.Secondary5,
        height: 40,
        borderRadius: 4,
        transition: 'box-shadow 0.2s, color 0.2s, background-color 0.2s',
        color: Colors.Secondary1,
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 500,
        maxWidth: 160,
        cursor: 'pointer',

        '& .MuiSvgIcon-root': {
            fill: Colors.Secondary1,
        },

        '&:hover': {
            boxShadow: `0px 4px 8px ${Colors.Gray8}`,
            color: Colors.White,
            backgroundColor: Colors.Secondary1,

            '& .MuiSvgIcon-root': {
                fill: Colors.White,
            },
        },
    },
    disabled: {
        opacity: 0.4,
        '&:hover': {
            boxShadow: 'none',
            color: Colors.Secondary1,
            backgroundColor: Colors.Secondary5,

            '& .MuiSvgIcon-root': {
                fill: Colors.Secondary1,
            },
        },
    },
    addIcon: {
        marginLeft: 18,
        marginRight: 15,
    },
}));

const CreationButton: React.VFC<CreationButtonProps> = ({
    onClick,
    title,
    className,
    withPlus = true,
    disabled = false,
    customDisabledTooltipText
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const permission = usePermissionContext();

    if (permission === PermissionType.Hidden) {
        return null;
    }

    const customDisabledTooltipTextHidden = !((customDisabledTooltipText || '').length > 0 && disabled);

    return (
        <CustomizedTooltip
            title={customDisabledTooltipText?.length 
                ? customDisabledTooltipText
                : t('common:noPermissionToResource')
            }
            interactive={false}
            copy={false}
            disableHoverListener={permission === PermissionType.Visible 
                && customDisabledTooltipTextHidden}
        >
            <Grid
                item
                className={classNames(
                    classes.root,
                    (permission === PermissionType.ReadOnly || disabled) && classes.disabled,
                    className,
                )}
                data-qa="add-to-new-group"
                onClick={
                    permission === PermissionType.Visible && !disabled ? onClick : undefined
                }
            >
                {withPlus && <Add className={classes.addIcon} />}
                {title}
            </Grid>
        </CustomizedTooltip>
    );
};

export default CreationButton;
