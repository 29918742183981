import { createAsyncAction } from 'typesafe-actions';
import {
    AddUAPayload,
    DeleteUARequest,
    GetUAInfoRequest,
    GetUAListRequest,
    GetUATypeListRequest,
    GetUploadUAApiErrorFileRequest,
    UAInfo,
    UAListPayload,
    UATypeListPayload,
    UpdateUaRequest,
    UploadUAListRequest,
} from '../../types/Devices';
import { APIErrorInterface } from '../../types/errors';

export const devicesList = createAsyncAction(
    'GET_UA_LIST',
    'GET_UA_LIST_SUCCESS',
    'GET_UA_LIST_FAILED',
)<GetUAListRequest, UAListPayload, undefined>();

export const releaseDevice = createAsyncAction(
    'RELEASE_DEVICE',
    'RELEASE_DEVICE_SUCCESS',
    'RELEASE_DEVICE_FAILED',
)<
    { extension_id: string; i_cpe?: number; onSuccess?: () => void },
    undefined,
    undefined
>();

export const assignDevice = createAsyncAction(
    'ASSIGN_DEVICE',
    'ASSIGN_DEVICE_SUCCESS',
    'ASSIGN_DEVICE_FAILED',
)<
    {
        withRelease?: boolean;
        port: number;
        i_cpe: number;
        i_c_ext: number;
        onSuccess?: () => void;
        extension_id?: string;
    },
    undefined,
    undefined
>();

export const fetchDeviceByIAccount = createAsyncAction(
    'FETCH_DEVICE_BY_I_ACCOUNT',
    'FETCH_DEVICE_BY_I_ACCOUNT_SUCCESS',
    'FETCH_DEVICE_BY_I_ACCOUNT_FAILED',
)<GetUAInfoRequest, { device: UAInfo | null }, undefined>();

export const deviceTypeList = createAsyncAction(
    'GET_UA_TYPE_LIST',
    'GET_UA_TYPE_LIST_SUCCESS',
    'GET_UA_TYPE_LIST_FAILED',
)<GetUATypeListRequest, UATypeListPayload, undefined>();

export const addDevice = createAsyncAction(
    'ADD_UA',
    'ADD_UA_SUCCESS',
    'ADD_UA_FAILED',
)<UAInfo, AddUAPayload, APIErrorInterface | undefined>();

export const getDeviceListCsvFile = createAsyncAction(
    'GET_UA_LIST_CSV_FILE',
    'GET_UA_LIST_CSV_FILE_SUCCESS',
    'GET_UA_LIST_CSV_FILE_FAILED',
)<
    GetUAListRequest & { fileName: string; callback?: () => void },
    undefined,
    undefined
>();

export const uploadDeviceListCsvFile = createAsyncAction(
    'UPLOAD_UA_LIST_CSV_FILE',
    'UPLOAD_UA_LIST_CSV_FILE_SUCCESS',
    'UPLOAD_UA_LIST_CSV_FILE_FAILED',
)<
    UploadUAListRequest,
    undefined,
    APIErrorInterface | undefined
>();

export const getUploadUAApiErrorFile = createAsyncAction(
    'GET_UA_LIST_ERRORS_CSV_FILE',
    'GET_UA_LIST_ERRORS_CSV_FILE_SUCCESS',
    'GET_UA_LIST_ERRORS_CSV_FILE_FAILED',
)<
    GetUploadUAApiErrorFileRequest,
    undefined,
    APIErrorInterface | undefined
>();

export const deleteUA = createAsyncAction(
    'DELETE_UA',
    'DELETE_UA_SUCCESS',
    'DELETE_UA_FAILED',
)<
    DeleteUARequest,
    undefined,
    APIErrorInterface | undefined
>();

export const updateUa = createAsyncAction(
    'UPDATE_UA',
    'UPDATE_UA_SUCCESS',
    'UPDATE_UA_FAILED',
)<UpdateUaRequest, undefined, APIErrorInterface | undefined>();