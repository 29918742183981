import React from "react";
import PoliciesForm from "./PoliciesForm";
import CallScreeningsTabs from "./CallScreeningsTabs";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    select: {
        marginTop: 10,
        maxWidth: 392,
    },
    itemsContainer: {
        padding: '22px 16px 17px 16px',
        maxWidth: 970,
        display: 'flex',
        flexDirection: 'column',
    },
    policyFormContainer: {
        paddingLeft: 24,
        paddingBottom: 31
    }
}));

export type CallScreeningFormProps = {};

const CallScreeningForm: React.VFC<CallScreeningFormProps> = () => {
    const classes = useStyles();

    return (
        <div>
            <Grid item className={classes.itemsContainer}>
                <div className={classes.policyFormContainer}>
                    <PoliciesForm/>
                </div>
                <CallScreeningsTabs/>
            </Grid>
        </div>
    )
};

export default CallScreeningForm;