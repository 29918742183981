import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../..';
import { APIErrorInterface } from '../../types';

export type PhoneBookListItem = {
    id: number;
    description?: string;
    abbreviated_number?: string;
    number_to_dial?: string;
};

export interface PhoneBookState {
    phonesBookList: {
        total: number;
        items: PhoneBookListItem[];
    };
    isLoading: boolean;
    savingPhoneBook?: boolean;
    deletingEntries?: boolean;
    errorsApi?: APIErrorInterface;
    max_abbreviated_length?: number;
}

export const initialState = {
    phonesBookList: {
        total: 0,
        items: [],
    },
    isLoading: false,
    savingPhoneBook: false,
    deletingEntries: false,
};

export type PhoneBookActions = ActionType<typeof actions>;

export const phoneBookReducer = createReducer<PhoneBookState, PhoneBookActions>(
    initialState,
)
    .handleAction(actions.getPhoneBookList.request, (state) => ({
        ...state,
        isLoading: true,
    }))
    .handleAction(actions.getPhoneBookList.success, (state, action) => ({
        ...state,
        phonesBookList: {
            ...state.phonesBookList,
            ...action.payload,
        },
        isLoading: false,
    }))
    .handleAction(actions.getMaxAbbreviated.success, (state, action) => ({
        ...state,
        max_abbreviated_length: action.payload.max_abbreviated_length,
    }))
    .handleAction(actions.getPhoneBookList.failure, (state) => ({
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.addNewPhoneBook.request, (state) => ({
        ...state,
        savingPhoneBook: true,
    }))
    .handleAction(actions.addNewPhoneBook.success, (state, action) => ({
        ...state,
        savingPhoneBook: false,
        max_abbreviated_length: action.payload?.max_abbreviated_length || state.max_abbreviated_length
    }))
    .handleAction(actions.addNewPhoneBook.failure, (state, action) => ({
        ...state,
        savingPhoneBook: false,
        errorsApi: action.payload,
    }))
    .handleAction(actions.deletePhoneBook.request, (state) => ({
        ...state,
        deletingEntries: true,
        isLoading:true
    }))
    .handleAction(actions.deletePhoneBook.success, (state) => ({
        ...state,
        deletingEntries: false,
    }))
    .handleAction(actions.deletePhoneBook.failure, (state) => ({
        ...state,
        deletingEntries: false,
    }));

export default phoneBookReducer;
