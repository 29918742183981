import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../store/types';
import { periodStringToArray } from '../utils/extensions/CallScreeningTimeWindowToPeriod';
import { MenuAction, UserInput } from '../store/types/AutoAttendant';
import { IntervalStatus } from '../components/IntervalSelect/IntervalSelect';

export const useAutoAttendantMenuFormTab = () => {
    const { autoAttendantMenus } = useSelector(
        (state: ReduxState) => state.autoAttendants,
    );

    const callQueues = useSelector(
        (state: ReduxState) => state.callQueues?.callQueues || [],
    );

    const initValues = useMemo(() => {
        const data =  {
            menus:
                autoAttendantMenus?.map((v) => {
                    const intervals = periodStringToArray({
                        period: v.period,
                        description: v.period_desc,
                    });

                    const nonActiveTransition = v.menuTransitions?.find(
                        (v) => v.userInput === UserInput.NotActive,
                    );

                    return {
                        menuId: v.i_menu,
                        name: v.name,
                        inactiveMenuAction:
                            nonActiveTransition?.action || MenuAction.DoNothing,
                        playBeforeAction: !!nonActiveTransition?.playBeforeActionStatus,
                        recordBeforeActionName: nonActiveTransition?.playBeforeActionStatus
                            ? 'prompt.au'
                            : '',
                        recordBeforeActionFile: null,
                        allowCallersToDialKnownNumber:
                            v.direct_dial_enabled === 'Y',
                        callersToDialKnownNumberTimeout: v.next_digit_timeout.toString(),
                        errorsCount: v.replay_menu_times.toString() || '0',

                        menu: autoAttendantMenus?.find(
                            (v) =>
                                v.i_menu === nonActiveTransition?.menu?.i_menu,
                        ),
                        queue: callQueues?.find(
                            (v) =>
                                v.i_c_queue ===
                                nonActiveTransition?.queue?.i_c_queue,
                        ),
                        transferDestination:
                            nonActiveTransition?.transferCallerToPhoneNumberExtension ||
                            '',
                        maxDigits:
                            nonActiveTransition?.maxDigits?.toString() || '4',

                        intervals: {
                            activity:
                                intervals[0].description === 'Always'
                                    ? IntervalStatus.Always
                                    : IntervalStatus.OnlyFollowingTimeInterval,
                            oldIntervals:
                                intervals[0].description === 'Always'
                                    ? []
                                    : intervals,
                            newIntervals: [],
                        },
                        introPromptStatus: v.msg_intro_type === 'custom',
                        introPromptFileName:
                            v.msg_intro_set === 1 ? 'intro.au' : '',
                        introPromptFile: null,
                        menuPromptStatus: v.msg_menu_type === 'custom',
                        menuPromptFileName:
                            v.msg_menu_set === 1 ? 'menu.au' : '',
                        menuPromptFile: null,
                        onTimeoutPromptStatus: v.msg_timeout_type === 'custom',
                        onTimeoutPromptFileName:
                            v.msg_timeout_set === 1 ? 'timeout.au' : '',
                        onTimeoutPromptFile: null,
                        onUnavailableSelectionPromptStatus:
                            v.msg_disabled_type === 'custom',
                        onUnavailableSelectionPromptFileName:
                            v.msg_disabled_set === 1 ? 'disabled.au' : '',
                        onUnavailableSelectionPromptFile: null,
                        actions: v.menuTransitions || [],
                    };
                }) || [],
        };

        return data;
    }, [autoAttendantMenus, callQueues]);

    return { initValues };
};
