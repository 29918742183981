import React from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';
import CustomizedTooltip from '../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

type ButtonProps = {
    children: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    primary?: boolean;
    accent?: boolean;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
    icon?: NonNullable<React.ReactNode>;
    snackbar?: boolean;
    dialog?: boolean;
    dataQa?: string;
    dataTestId?: string;
    className?: string;
    secondary?: boolean;
    ternary?: boolean;
    skipPermission?: boolean;
    customDisabledTooltipText?: string;
};

const useStyles = makeStyles(() => ({
    basic: {
        color: Colors.Primary,
        fontWeight: 700,
        borderRadius: '4px',
        padding: '6px 12px',
        textTransform: 'none',
        fontSize: 16,
        minWidth: 96,

        '&:hover': {
            background: 'transparent',
            opacity: 0.88,
        },
        '& svg': {
            '& path': {
                fill: Colors.White,
                fillOpacity: 1,
            },
        },
    },
    primary: {
        color: Colors.Text,
        background: Colors.Primary,

        '&:hover': {
            background: Colors.Primary,
        },

        '&.MuiButton-root.Mui-disabled': {
            backgroundColor: Colors.Gray8,
            '& .MuiButton-label': {
                color: Colors.Gray10,
            },

            '& svg': {
                '& path': {
                    fill: `${Colors.Gray10} !important`,
                    fillOpacity: 1,
                },
            },
        },
    },
    secondary: {
        background: Colors.Secondary1,
        '& .MuiButton-label': {
            color: `${Colors.White}!important`,
            fontSize: '16px !important',
        },
        '&:hover': {
            background: `${Colors.Secondary1}!important`,
        },
        '& .MuiTouchRipple-root': {
            color: `${Colors.Gray10}!important`,
        },
    },
    ternary: {
        background: 'transparent',
        '& .MuiButton-label': {
            color: `${Colors.Gray5}!important`,
            fontSize: '16px !important',
        },
        '& .MuiTouchRipple-root': {
            color: `${Colors.Gray10}!important`,
        },
        '&:hover': {
            background: `${Colors.Gray2}!important`,
        },
        '& svg': {
            '& path': {
                fill: Colors.Gray5,
                fillOpacity: 1,
            },
        },
        '&.MuiButton-root.Mui-disabled': {
            '& .MuiButton-label': {
                color: `${Colors.Gray10}!important`,
            },

            '& svg': {
                '& path': {
                    fill: `${Colors.Gray10} !important`,
                    fillOpacity: 1,
                },
            },
        },
    },
    accent: {
        color: Colors.Text,
        background: Colors.Secondary1,

        '&:hover': {
            background: Colors.Secondary1,
        },
    },
    snackbar: {
        color: Colors.Secondary1,
    },
    dialog: {
        color: Colors.Gray,
    },
}));

const CustomizedButton: React.FC<ButtonProps> = ({
    children,
    primary,
    secondary,
    ternary,
    onClick,
    accent,
    disabled = false,
    type,
    icon,
    snackbar,
    dialog,
    dataQa,
    className,
    dataTestId,
    skipPermission,
    customDisabledTooltipText
}) => {
    const classes = useStyles();
    let permission = usePermissionContext();
    const { t } = useTranslation();

    permission = skipPermission ? PermissionType.Visible : permission;

    if (permission === PermissionType.Hidden) {
        return <div />;
    }

    const customDisabledTooltipTextHidden = !((customDisabledTooltipText || '').length > 0 && disabled);

    return (
        <CustomizedTooltip
            title={customDisabledTooltipText?.length 
                ? customDisabledTooltipText
                : t('common:noPermissionToResource')}
            interactive={false}
            copy={false}
            disableHoverListener={permission === PermissionType.Visible 
                && customDisabledTooltipTextHidden}
        >
            <div>
                <Button
                    className={classNames(className, classes.basic, {
                        [classes.primary]: primary,
                        [classes.secondary]: secondary,
                        [classes.ternary]: ternary,
                        [classes.accent]: accent,
                        [classes.snackbar]: snackbar,
                        [classes.dialog]: dialog,
                    })}
                    onClick={onClick}
                    disabled={
                        disabled || permission === PermissionType.ReadOnly
                    }
                    type={type}
                    startIcon={icon}
                    data-qa={dataQa}
                    data-testid={dataTestId}
                >
                    {children}
                </Button>
            </div>
        </CustomizedTooltip>
    );
};

export default CustomizedButton;
