import { Colors } from "../../../../styles/Colors";
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import i18n from "../../../../services/i18n";
import { ApiFile } from "../../../../store/types/ApiFile";

export type NewResponseMessagesDialogProps = {
    isOpen: boolean;
    editObject?: ApiFile;
    clickOnClose?: () => void;
};

const requiredError = i18n.t<string>('errors:common.emptyInput');
const fileIsMandatory = i18n.t<string>('errors:callSettings.uploadFileIsMandatory');
const fileIsTooLarge = i18n.t<string>('errors:callSettings.uploadFileSize');
export const newFileValidationSchema = Yup.object().shape({
    file_name: Yup.string()
        .required(requiredError),
    fileDisplayName: Yup.string().when('i_object', {
        is: (i_object: number) => {
            return i_object && i_object > 0;
        },
        then: Yup.string()
            .notRequired(),
        otherwise: Yup.string().when('fileSize', {
            is: (fileSize?: number) => {
                const sizeInMb = parseFloat((fileSize || 0)  + '') / (1024.0 * 1024.0);
                return sizeInMb >= 5.0;
            },
            then: Yup.string()
                .required(fileIsMandatory)
                .matches(/\\\//i, fileIsTooLarge),
            otherwise: Yup.string()
                .required(fileIsMandatory),
        }),
    }),
    file: Yup.object()
        .nullable()
        .notRequired(),
});

export const useStyles = makeStyles(() => ({
    modalContainer: {
        '& .MuiDialogContent-root': {
            background: Colors.SmokeBackground,
            height: 'fit-content',
            width: 576,
            padding: 0,
            margin: 0,
        },

        '& .MuiDialog-paperWidthSm': {
            maxWidth: 'unset',
            maxHeight: 'unset'
        },

        '& .MuiGrid-root:first-child': {
            marginTop: 0,
        },

        '& .MuiPaper-root': {
            width: 576,
        },
        
        '& .MuiFormHelperText-root': {
            right: 0,
            fontSize: '0.75rem',
            fontWeight: 400
        },
    },

    header: {
        '& .MuiTypography-h6': {
            fontWeight: 700,
            fontSize: 18,
        },
    },

    itemsContainer: {
        paddingLeft: 56,
        paddingTop: 2,
        paddingRight: 111,
        paddingBottom: 38,
        display: 'flex',
        flexDirection: 'column',

        '& p': {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '20px',
        },

        '& .MuiFormControl-root': {
            width: 409,
            height: 56
        },
    },

    form: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        padding: 0,
        margin: 0,
        overflowX: 'hidden'
    },
    
    boxRow: {
        display: 'flex',
        alignItems: 'flex-start',
        width: 472,
        marginTop: 32,

        '& .Mui-error': {
            fontWeight: 400,
        },
    },

    helperBox: {
        height: 12,
        paddingTop: 0,
        paddingBottom: 0
    },
    
    browseButton: {
        marginTop: 8,
        color: Colors.White,
        width: 90,
        height: 40,
    },

    fileDisplayName: {
        width: '289px !important',
        marginRight: 24,
    },
    
    descriptionFormats: {
        fontSize: 12,
        marginLeft: 16,
        marginTop: 8,
        color: Colors.Gray5,
    },
}));