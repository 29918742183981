import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { UAInfo, UAPortConfiguration } from '../../store/types/Devices';
import classNames from 'classnames';
import { TFunction, useTranslation } from 'react-i18next';
import ActionsButtons from './ActionsButtons';
import ExtensionCell from './ExtensionCell';
import { Colors } from '../../styles/Colors';
import { DialogButton } from '../AlertDialog/DialogContainer';
import AlertDialog from '../AlertDialog/AlertDialog';
import EditDeviceDialog from './EditDeviceDialog';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../store';
import { ReduxState } from '../../store/types';
import Loader from '../Loader/Loader';
import DataGrid from '../DataGrid/DataGrid';
import { Column } from 'react-table';
import PermissionProvider from '../PermissionProvider/PermissionProvider';
import { Permission } from '../../store/types/Permission';
import TextButton from '../TextButton/TextButton';

const useStyles = makeStyles(() => ({
    root: {},
    table: {
        '& td.MuiTableCell-root': {
            padding: 8,
        },
        '& thead > tr.MuiTableRow-root': {
            minHeight: 63,
            '& th': {
                padding: 8,
            },
            '& th:first-child': {
                paddingLeft: 16,
            },
            '& th:last-child': {
                paddingRight: 16,
            },
        },
        '& tbody tr': {
            alignItems: 'center',
            minHeight: 52,
            '& td:first-child': {
                paddingLeft: 16,
            },
            '& td:last-child': {
                paddingRight: 16,
            },
        },
        '& button': {
            width: 100,
            justifyContent: 'flex-start',
            '& span': {
                fontSize: 16,
                fontWeight: 700,
            },
        },
    },

    releaseDialog: {
        '& .MuiDialogContent-root': {
            padding: 24,
            maxWidth: '336px',
            minWidth: '336px',
        },

        '& p': {
            marginBottom: 0,
            fontSize: 16,
        },
    },
    editDialog: {
        '& .MuiDialogContent-root': {
            padding: 24,
            maxWidth: '568px',
            minWidth: '568px',
            backgroundColor: Colors.SmokeBackground,
        },

        '& p': {
            marginBottom: 0,
            fontSize: 16,
        },
    },
}));

type PortConfigListProps = {
    device?: UAInfo;
    dataQa?: string;
    className?: string;
    isLoading?: boolean;
};
const PortsConfigList: React.FC<PortConfigListProps> = ({
    device,
    className,
    dataQa,
    isLoading,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [deviceModal, setDeviceModal] = useState<{
        isOpen: boolean;
        line?: number;
        extension_id?: string;
        account_id?: string;
    }>();
    const [editModal, setEditModal] = useState<{
        isOpen: boolean;
        extension_id?: string;
        i_ua?: number;
        line?: number;
        account_id?: string;
    }>();
    const { isReleasingDevice } = useSelector(
        (state: ReduxState) => state.devices,
    );

    const handleReleaseDevice = () => {
        deviceModal?.extension_id &&
            dispatch(
                actions.releaseDevice.request({
                    extension_id: deviceModal.extension_id,
                    i_cpe: device?.i_ua,
                    onSuccess: () =>
                        setDeviceModal((prev) => ({
                            ...prev,
                            isOpen: false,
                        })),
                }),
            );
    };

    const handleReleaseClick = (line: number, extension_id: string) => {
        setDeviceModal({
            isOpen: true,
            line: line,
            extension_id: extension_id,
        });
    };

    const handleEditClick = (
        iDIDNumber: string,
        account_id: string,
        line: number,
        i_ua: number,
    ) => {
        setEditModal({
            isOpen: true,
            extension_id: iDIDNumber,
            i_ua: i_ua,
            line: line,
            account_id,
        });
    };

    const columns = useMemo(
        () =>
            generateColumns(
                t,
                handleReleaseClick,
                handleEditClick,
                device?.i_ua,
            ),
        [],
    );
    const items = device?.ports_config?.map((c) => ({ ...c, id: c.port }));

    return (
        <div
            className={classNames(classes.root, className)}
            data-qa={dataQa}
            data-testid={'ports-config-list'}
        >
            <DataGrid<UAPortConfiguration & { id: number }>
                className={classes.table}
                columns={columns}
                data={items?.length ? items : []}
                rowCount={items?.length ?? 0}
                hidePagination
                loading={isLoading}
                hideFooter
            />
            <AlertDialog
                className={classes.releaseDialog}
                isOpen={!!deviceModal?.isOpen}
                dataQa={'release-device-modal'}
                description={
                    isReleasingDevice ? (
                        <Loader dataQa={'release-loader'} />
                    ) : (
                        t('screens:devices.releaseLine', {
                            line: deviceModal?.line,
                        })
                    )
                }
                hideHeader={true}
                dataTestId={'release-dialog'}
                dialogActionsButtons={[
                    <DialogButton
                        key="no"
                        label={t('common:cancel')}
                        onClick={() =>
                            setDeviceModal({
                                ...deviceModal,
                                isOpen: false,
                            })
                        }
                    />,
                    <DialogButton
                        key="yes"
                        label={t('common:release')}
                        onClick={handleReleaseDevice}
                    />,
                ]}
            />
            <EditDeviceDialog
                className={classes.editDialog}
                isOpen={!!editModal?.isOpen}
                line={editModal?.line}
                extension_id={editModal?.extension_id}
                account_id={editModal?.account_id}
                cancelClick={() => {
                    setEditModal({ ...editModal, isOpen: false });
                }}
                i_ua={device?.i_ua}
                toggleModal={() =>
                    setEditModal((prev) => ({
                        ...prev,
                        isOpen: false,
                    }))
                }
            />
        </div>
    );
};

export default PortsConfigList;

const generateColumns = (
    t: TFunction<string>,
    handleReleaseClick: (line: number, extension_id: string) => void,
    handleEditClick: (
        extension: string,
        account: string,
        line: number,
        i_ua: number,
    ) => void,
    i_ua?: number,
): Column<UAPortConfiguration & { id: number }>[] => {
    return [
        {
            accessor: 'port',
            minWidth: 90,
            maxWidth: 90,
            Header: t<string>('screens:devices.line'),
            Cell: function Cell(params) {
                return (
                    <PermissionProvider
                        permission={Permission.Inventory.Devices.DeviceDetails.EditPort.value}
                    >
                        <TextButton
                            onClick={() =>
                                handleEditClick(
                                    params.row.original.extension_id!,
                                    params.row.original.account_id!,
                                    params.row.original.port,
                                    i_ua || 1,
                                )
                            }
                        >
                            {params.row.original.port}.
                        </TextButton>
                    </PermissionProvider>
                );
            },
        },
        {
            accessor: 'extension_id',
            width: 2,
            Header: t<string>('screens:extensions.extension'),
            Cell: function Cell(params) {
                return (
                    <ExtensionCell
                        mainValue={params.row.original.extension_id}
                        secondaryValue={params.row.original.account_id}
                        emptyText={t('screens:devices.freeLinesPorts')}
                    />
                );
            },
        },
        {
            accessor: 'did_number',
            width: 1,
            Header: t<string>('screens:extensions.didNumber'),
            Cell: function Cell(params) {
                return <span>{params.row.original.did_number}</span>;
            },
        },
        {
            accessor: 'extension_name',
            width: 2,
            Header: t<string>('common:name'),
            Cell: function Cell(params) {
                return <span>{params.row.original.extension_name}</span>;
            },
        },
        {
            accessor: 'id',
            width: 2,
            Header: (
                <div style={{ textAlign: 'center' }}>
                    {t<string>('common:actions')}
                </div>
            ),
            Cell: function Cell(params) {
                return (
                    <ActionsButtons
                        release={!!params.row.original.extension_id}
                        releaseClick={() =>
                            handleReleaseClick(
                                params.row.original.port,
                                params.row.original.extension_id!,
                            )
                        }
                        editClick={() => {
                            handleEditClick(
                                params.row.original.extension_id!,
                                params.row.original.account_id!,
                                params.row.original.port!,
                                1,
                            );
                        }}
                    />
                );
            },
        },
    ];
};
