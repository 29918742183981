import React from 'react';
import i18n from 'i18next';
import {CallerID, RingStrategy} from '../../store/types/RingGroup';
import {Colors} from '../../styles/Colors';
import * as Yup from 'yup';
import {ReactComponent as CallHistory} from '../../assets/call_history.svg';
import PeopleIcon from '../../assets/people';
import DataItemActions from '../../components/DataTable/DataItemActions';
import {TFunction, useTranslation} from 'react-i18next';
import MembersList from '../../components/RingGroups/MembersList';
import CustomizedTooltip from '../../components/Tooltip/Tooltip';
import history from '../../history';
import {Column} from 'react-table';
import {makeStyles} from '@material-ui/core';
import {RingGroupType} from '../../store/types/RingGroup';
import OverflowTooltip from '../../components/OverflowTooltip/OverflowTooltip';
import CustomizedIconButton from '../../components/IconButton/IconButton';
import classNames from 'classnames';
import {Routes, getRoute} from '../../routes/routes';
import {Permission, PermissionType} from '../../store/types/Permission';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import PermissionLinkCell from "../../components/DataTable/PermissionLinkCell";
import { CustomerOfficeType } from '../../store/types/CustomerInfo';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        height: '100vh',
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        background: Colors.SmokeBackground,
        '& .MuiButton-label': {
            color: Colors.White,
        },
    },

    scrollable: {
        overflowY: 'auto',
        flex: 1,
        padding: '48px 96px',
        '& .MuiTableContainer-root': {
            //width: 'calc(100% - 192px)',
            width: 'unset !important',
            maxWidth: 1040
        },
        //maxWidth: 1040
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 25,
        '& h2': {
            fontSize: 32,
            lineHeight: '24px',
            marginBlockEnd: 8,
            marginBlockStart: 4,
            fontWeight: 500,
        },
        '& p': {
            fontSize: 11,
            lineHeight: '16px',
            opacity: 0.38,
            marginBlockStart: 0,
        },
    },
    filters: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 17,
    },
    filtersHidden: {
        width: 50,
        overflow: 'hidden',
        marginBottom: -92,
    },
    pressableSearchIcon: {
        marginTop: 10,
        marginRight: 17,
        marginLeft: 17,
        cursor: 'pointer',
    },
    filtersContainer: {
        marginBottom: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: 1040
    },
    filtersInputs: {
        flex: 1,
        display: 'flex',
        '& MuiFormControl-root': {
            flex: 1,
            maxWidth: 400,
        },
    },
    headerRow: {
        textTransform: 'uppercase',
        border: 0,
    },
    headerCell: {
        fontWeight: 700,
        fontSize: 12,
    },
    row: {
        borderBottom: 0,
    },
    filterSeparation: {
        marginRight: 18,
        width: 'calc(50%)'
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    tableContainer: {
        maxWidth: 1040,
        overflowX: 'hidden',

        '& .MuiTableRow-head': {
            '& :nth-child(1)': {
                textAlign: 'center',
            },
        }
    },
    centeredRow: {
        display: 'flex',
        justifyContent: 'center',
        '& > div': {
            display:'flex',
            justifyContent: 'center',
        }
    },
    searchButton: {
        marginLeft: 6,
        width: 96,
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'center',
        height: 56,
        marginTop: 24,
        width: '100%'
    },
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    actions: {
        display: 'flex',
        paddingLeft: 0,
        marginLeft: '-3.5rem',

        '& .MuiIconButton-root': {
            marginRight: 5,
        },
    },
    actionWrapper: {
        marginRight: 5,
        marginLeft: 5,
    },
    callQueue: {
        width: '80px',
        paddingLeft: 25,
    },
    maxHeightCell: {
        flex: 1,
        height: 50,
        alignSelf: 'flex-start',
        alignItems: 'center',
        display: 'flex',
        overflow: 'hidden',
    },
    disableOverflow: {
        overflow: 'unset',
    },
}));

export type CreateNewRingGroupFormData = {
    name: string;
    number: string;
    callerId: CallerID;
    ringStrategy: RingStrategy;
    callRecording: boolean;
};

const emptyInputText = i18n.t<string>('errors:common.emptyInput');

export const ringGroupFormValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required(emptyInputText)
        .max(32, i18n.t<string>('errors:ringGroups.numberMaxLength32')),
    number: Yup.string()
        .matches(/[0-9]+/, i18n.t<string>('errors:ringGroups.numberOnlyDigits'))
        .max(5, i18n.t<string>('errors:ringGroups.numberMaxLength5'))
        .required(emptyInputText),
    callerId: Yup.string().required(),
    ringStrategy: Yup.string().required(),
});

export const generateColumns = (
    t: TFunction<string>,
    ringGroupDelete: (id: number, name: string) => void,
    onCallHistoryClick: (param: RingGroupType) => void,
    classes: ReturnType<typeof useStyles>,
    i_office_type: CustomerOfficeType,
    i_customer: number
): Column<RingGroupType>[] => {
    return [
        {
            Header: t<string>('common:number'),
            accessor: 'id',
            Cell: function Cell(params) {
                return (
                    <div
                        className={classNames(
                            classes.maxHeightCell,
                            classes.centeredRow,
                        )}
                    >
                        <PermissionLinkCell
                            text={params.row.original.id}
                            onClick={() => {
                                history.push(
                                    getRoute(Routes.RingGroupsDetails, {
                                        id: params.row.original.i_c_group,
                                    }),
                                );
                            }}
                            permissions={Permission.CloudPBX.RingGroups.RingGroupDetails.value}
                            customizePermission={(value) => {
                                if(value === PermissionType.Visible && params.row.original.i_customer !== i_customer) {
                                    return PermissionType.Hidden;
                                }
                                return value;
                            }}
                        />
                    </div>
                );
            },
            maxWidth: 100,
            minWidth: 100,
        },
        {
            Header: t<string>('common:name'),
            accessor: 'name',
            Cell: function Cell(params) {
                return (
                    <div className={classes.maxHeightCell}>
                        <OverflowTooltip
                            dataQa="hunt_keep_orginal_cli"
                            text={params.row.original.name}
                            tooltip={params.row.original.name}
                            copy={false}
                        />
                    </div>
                );
            },
            width: 1,
        },
        {
            Header: t<string>('screens:ringGroups.ringStrategy'),
            accessor: 'hunt_sequence',
            Cell: function Cell(params) {
                const value =
                    params.row.original.hunt_sequence === 'LeastUsed'
                        ? t('screens:ringGroups.leastUsed')
                        : params.row.original.hunt_sequence;
                return (
                    <div className={classes.maxHeightCell}>
                        <OverflowTooltip
                            dataQa="hunt_keep_orginal_cli"
                            text={value}
                            tooltip={value}
                            copy={false}
                        />
                    </div>
                );
            },
            width: 1,
        },
        {
            Header: t<string>('screens:ringGroups.groupMembers'),
            accessor: 'assigned_extensions',
            Cell: function Cell(params) {
                const extensions = params.row.original.assigned_extensions!.filter(
                    (v: any) => v.type === 'Account',
                );
                const ringGroups = params.row.original.assigned_extensions!.filter(
                    (v: any) => v.type === 'Group',
                );

                return (
                    <MembersList
                        extensions={extensions}
                        ringGroups={ringGroups}
                    />
                );
            },
            width: 1,
        },
        {
            Header: t<string>('screens:ringGroups.callerId'),
            accessor: 'hunt_keep_original_cli',
            Cell: function Cell(params) {
                let title = '';
                const {t} = useTranslation();

                switch (params.row.original.hunt_keep_original_cli) {
                    case 'Y':
                        title = t('screens:ringGroups.keepOriginal');
                        break;
                    case 'N':
                        title = t('screens:ringGroups.setRingGroupName');
                        break;
                    default:
                        title = t('screens:ringGroups.replaceCallerInfo');
                }

                return (
                    <div className={classes.maxHeightCell}>
                        <span style={{fontSize: 12}}>{title}</span>
                    </div>
                );
            },
            width: 1.2,
        },
        {
            Header: t<string>('screens:ringGroups.callQueue'),
            accessor: 'assigned_callqueue',
            Cell: function Cell(params) {
                const value =
                    params.row.original?.assigned_callqueue
                        ?.incoming_capacity || '';
                return (
                    <div className={classes.maxHeightCell}>
                        <div className={classes.callQueue}>
                            {params.row.original.assigned_callqueue && (
                                <CustomizedTooltip
                                    title={t(
                                        'tooltips:ringGroups.callQueueListItem',
                                        {
                                            value,
                                        },
                                    )}
                                    copy={false}
                                >
                                    <div>
                                        <PeopleIcon value={value}/>
                                    </div>
                                </CustomizedTooltip>
                            )}
                        </div>
                    </div>
                );
            },
            minWidth: 170,
            maxWidth: 170,
        },
        {
            Header: t<string>('common:actions'),
            accessor: 'i_c_group',
            Cell: function Cell(params) {
                return (
                    <div
                        className={classNames(
                            classes.maxHeightCell,
                            classes.disableOverflow,
                        )}
                    >
                        <div className={classes.actions}>
                            <div className={classes.actionWrapper}>
                                <PermissionProvider
                                    permission={
                                        Permission.CloudPBX.RingGroups
                                            .ViewCallHistory.value
                                    }
                                    skipReadOnly
                                >
                                    <CustomizedIconButton
                                        onClick={() =>
                                            onCallHistoryClick(
                                                params.row.original,
                                            )
                                        }
                                        data-testid="view-call-history-list-item-button"
                                        data-qa="view-call-history-list-item-button"
                                        tooltipText={t(
                                            'tooltips:ringGroups.viewCallHistory',
                                        )}
                                    >
                                        <CallHistory/>
                                    </CustomizedIconButton>
                                </PermissionProvider>
                            </div>

                            <DataItemActions
                                onDelete={() =>
                                    ringGroupDelete(
                                        params.row.original.i_c_group,
                                        params.row.original.name,
                                    )
                                }
                                onEdit={() => {
                                    history.push(
                                        getRoute(Routes.RingGroupsDetails, {
                                            id: params.row.original.i_c_group,
                                        }),
                                    );
                                }}
                                editPermission={
                                    Permission.CloudPBX.RingGroups
                                        .RingGroupDetails.value
                                }
                                deletePermission={
                                    Permission.CloudPBX.RingGroups
                                        .DeleteRingGroup.value
                                }
                                customizeDeletePermission={(value) => {
                                    if(value === PermissionType.Visible && i_office_type === CustomerOfficeType.Branch) {
                                        return PermissionType.ReadOnly;
                                    }
                                    return value;
                                }}
                                skipEditPermissionReadOnly={false}
                                customizeEditPermission={(value) => {
                                    if(value === PermissionType.Visible && params.row.original.i_customer !== i_customer) {
                                        return PermissionType.ReadOnly;
                                    }
                                    return value;
                                }}
                            />
                        </div>
                    </div>
                );
            },
            minWidth: 140,
            maxWidth: 140,
        },
    ];
};
