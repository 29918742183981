import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../../store/types/store";
import { Colors } from "../../../../styles/Colors";
import DataGrid from "../../../DataGrid/DataGrid";
import { TFunction, useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import CustomizedIconButton from "../../../IconButton/IconButton";
import { Delete, Edit } from "@material-ui/icons";
import CreationButton from "../../../CreationButton/CreationButton";
import { ApiFile } from "../../../../store/types/ApiFile";
import StickyCell from "./StickyCell";
import { Permission } from "../../../../store/types/Permission";
import { GetCallProcessingPolicyListEntity } from "../../../../store/actions/extensions/payloads";
import { NewResponseMessagesDialogProps } from "./NewResponseMessagesDialog.utils";
import NewResponseMessagesDialog from "./NewResponseMessagesDialog";

export type ResponseMessagesTabProps = {
    onDelete: (mode: ApiFile) => void
};

const useStyles = makeStyles(() => ({
    tabsContainer:{
        backgroundColor: Colors.White,
        border: 'none',
        width: 'calc(100% - 64px)',
        paddingLeft: 32,
        paddingRight: 32,

        '& table': {
            width: 760,

            '& .MuiTableBody-root .MuiTableCell-root': {
                height: 57
            },
        }
    },
    temporaryIcon: {
        color: Colors.Error,
        border: `1px solid ${Colors.Error}`,
        padding: '3px 8px',
        marginLeft: 16
    },
    addApiFileButton: {
        marginTop: 16,
        marginLeft: 32,
        marginBottom: 22
    }
}));

export const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    policies: GetCallProcessingPolicyListEntity[],
    onEdit?: (object: ApiFile) => void, 
    onDelete?: (object: ApiFile) => void, 
): Column<ApiFile>[] => {

    const isFileInUse = (mode: ApiFile) => {
        if(!policies?.length) return false;
        for(const policy of policies) {
            if(!policy?.rule_list?.length) continue;
            for(const rule of policy.rule_list) {
                const messageId = rule.i_response_message || 0;
                if(messageId === mode.id) {
                    return true;
                }
            }
        }
        return false;
    };

    return [
        {
            Header: t<string>('common:name'),
            accessor: 'name',
            width: 2,
            Cell: function Cell(params) {
                const name = params.row.original.name;
                const isTemporary = params.row.original.transitional === 'Y';
                return (
                    <StickyCell
                        text={name}
                        stickyText={t('screens:callSettings.temporary')}
                        isSticky={isTemporary}
                        customClasses={{
                            stickyText: classes.temporaryIcon
                        }}
                        onClick={() => {
                            onEdit?.(params.row.original);
                        }}
                        permissions={Permission.Calls.Settings.CallScreening.value}
                    />
                );
            },
        },
        {
            Header: '',
            accessor: 'id',
            width: 1,
            Cell: function Cell(params) {
                const original = params.row.original;
                return (
                <>
                    <CustomizedIconButton
                        onClick={() => {
                            onEdit?.(original);
                        }}
                        dataTestId="edit-file-item-button"
                        dataQa="edit-file-item-button"
                        tooltipText={t<string>('common:edit')}
                    >
                        <Edit htmlColor={Colors.ListIconColor}/>
                    </CustomizedIconButton>
                    {!isFileInUse(original) && (
                        <CustomizedIconButton
                            onClick={() => {
                                onDelete?.(original);
                            }}
                            dataTestId="remove-file-item-button"
                            dataQa="remove-file-item-button"
                        >
                            <Delete htmlColor={Colors.ListIconColor}/>
                        </CustomizedIconButton>
                    )}
                </>);
            },
        }
    ];
}

const ResponseMessagesTab: React.FC<ResponseMessagesTabProps> = ({
    onDelete
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    const policies = useSelector(
        (state: ReduxState) => state.extensions?.callProcessingPolicyList || [],
    );
    
    const files = useSelector(
        (state: ReduxState) => state.callSettings?.customerResponseMessages?.file_list || [],
    );

    const [showAddEditFileDialog, setShowAddEditFileDialog] = useState<NewResponseMessagesDialogProps>({
        isOpen: false
    });

    const columns = generateColumns(
        t,
        classes,
        policies,
        (object: ApiFile) => {
            setShowAddEditFileDialog({
                isOpen: true,
                editObject: object,
                clickOnClose: () => {
                    setShowAddEditFileDialog({
                        isOpen: false,
                        editObject: undefined,
                    });
                }
            });
        },
        onDelete
    );

    return (
        <>
            <DataGrid<ApiFile>
                classes={{
                    tableContainer: classes.tabsContainer,
                }}
                columns={columns}
                data={files}
                rowCount={files?.length || 0}
                pageSizeOptions={[files?.length || 0]}
                initialPageSize={Number.MAX_VALUE}
                centeredRows
                hideFooter
                getItemId={(r) => r.id}
            />
            <CreationButton
                data-qa="add-api-file-button"
                onClick={() => {
                    setShowAddEditFileDialog({
                        isOpen: true,
                        editObject: undefined,
                        clickOnClose: () => {
                            setShowAddEditFileDialog({
                                isOpen: false,
                                editObject: undefined,
                            });
                        }
                    });
                }}
                className={classes.addApiFileButton}
                title={t('screens:callSettings.addResponseMessage')}
            />
            <NewResponseMessagesDialog
                {...showAddEditFileDialog}
            />
        </>
    )
}

export default ResponseMessagesTab;