import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TFunction } from 'react-i18next';
import { ActiveCall } from '../../../store/types/CallHistory';
import { Column } from 'react-table';
import DateTimeCell from '../../../components/Calls/DateTimeCell';
import CallerInfo from '../../../components/Calls/CallerInfo';
import { Colors } from '../../../styles/Colors';
import TwoLineCell from '../../../components/Calls/TwoLineCell';

export const useStyles = makeStyles(() => ({
    tableContainer: {
        maxWidth: 1040,

        '& tr td.MuiTableCell-root.MuiTableCell-body:nth-child(4) span': {
            marginLeft: -7,
        },
    },

    connectingText: {
        fontSize: 14,
        fontStyle: 'italic',
        color: Colors.Gray5,
        fontFamily: 'Roboto',
    },
}));

const displayCallerText = (t: TFunction<string>, extension_id?: string, extension_name?: string) => {
    if(!extension_id?.length && !extension_name?.length)
        return '';
    const extName = !extension_name ? t('common:unnamed') : extension_name; 
    if(!extension_id?.length) {
        return extName;
    }
    return extension_id + " - " + extName;
}

export const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    timezoneOffset: number,
    userDateTimeFormat?: string,
): Column<ActiveCall>[] => {
    return [
        {
            accessor: 'connect_time',
            Header: t<string>('screens:calls.connectTime'),
            width: 1,
            Cell: function Cell(params) {
                return (
                    <DateTimeCell
                        timezoneOffset={timezoneOffset}
                        time={params.row.original.connect_time as string}
                        customDateFormat={userDateTimeFormat}
                        hideDateWhenIsToday
                    />
                );
            },
        },
        {
            accessor: 'cli',
            Header: t<string>('screens:calls.caller'),
            width: 1,
            Cell: function Cell(params) {
                return (
                    <TwoLineCell
                        headerText={params.row.original.cli}
                        descriptionText={
                            displayCallerText(t, params.row.original.extension_id, params.row.original.extension_name)
                        }
                    />
                );
            },
        },
        {
            accessor: 'cld',
            Header: t<string>('screens:calls.destination'),
            width: 1.3,
            Cell: function Cell(params) {
                return <CallerInfo caller={params.row.original.cld!} />;
            },
        },
        {
            accessor: 'duration',
            Header: t<string>('screens:calls.duration'),
            width: 2,
            Cell: function Cell(params) {
                if (
                    params.row.original.duration == 0 ||
                    params.row.original.duration == undefined
                ) {
                    return (
                        <span className={classes.connectingText}>
                            {t<string>('screens:calls.connecting')}...
                        </span>
                    );
                }

                return (
                    <span>
                        {new Date(params.row.original.duration! * 1000)
                            .toISOString()
                            .substr(11, 8)}
                    </span>
                );
            },
        },
    ];
};
