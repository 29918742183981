import { Colors } from "../../../../styles/Colors";
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import i18n from "../../../../services/i18n";
import { CPConditionInfo } from "../../../../store/types/CallScreening";

export enum TimeFiltersPageFilterType {
    TimeWindow = 'TimeWindow',
    FromNumber = 'FromNumber',
    ToNumber = 'ToNumber'
}

export type NewTimeFilterDialogProps = {
    isOpen: boolean;
    filterType: TimeFiltersPageFilterType;
    editObject?: CPConditionInfo;
    clickOnClose?: () => void;
};

export const numberValidation = (value?: string) => {
    return !!value &&  /^[+]?[a-dA-D0-9*#%_x]*$/.test(value);
};

const requiredError = i18n.t<string>('errors:common.emptyInput');
const numbersInvalidFormat = i18n.t('errors:common.invalidFromat');
export const newTimeFilterValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required(requiredError),
    numbers: Yup.array()
        .of(
            Yup.object().shape({
                number: Yup.string().test(
                    'number-test',
                    numbersInvalidFormat,
                    numberValidation,
                ).required(),
                onnet: Yup.string().notRequired(),
            }),
        ),
});

export const useStyles = makeStyles(() => ({
    modalContainer: {
        '& .MuiDialogContent-root': {
            background: Colors.SmokeBackground,
            height: 'fit-content',
            width: 576,
            padding: 0,
            margin: 0,
            overflowY: 'auto',
            maxHeight: 'calc(90vh - 122px)'
        },

        '& .MuiDialog-paperWidthSm': {
            maxWidth: 'unset',
            maxHeight: 'unset'
        },

        '& .MuiGrid-root:first-child': {
            marginTop: 0,
        },

        '& .MuiPaper-root': {
            width: 576,
        },
        
        '& .MuiFormHelperText-root': {
            right: 0,
            fontSize: '0.75rem',
            fontWeight: 400
        },
    },

    modalContainerMinHeight: {
        '& .MuiDialogContent-root': {
            minHeight: 327,
        }
    },

    header: {
        '& .MuiTypography-h6': {
            fontWeight: 700,
            fontSize: 18,
        },
    },

    itemsContainer: {
        paddingLeft: 56,
        paddingTop: 2,
        paddingRight: 80,
        paddingBottom: 53,
        display: 'flex',
        flexDirection: 'column',

        '& p': {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '20px',
        },

        '& .MuiFormControl-root': {
            width: 440,
            height: 56
        },
    },

    form: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        padding: 0,
        margin: 0,
        overflowX: 'hidden'
    },
    
    boxRow: {
        marginTop: 32,
        display: 'flex',
        alignItems: 'flex-start',
        width: 472,

        '& .Mui-error': {
            fontWeight: 400,
        },
    },

    boxRowOfNumbers: {
        width: 468,
        
        '& button': {
            width: 40,
            height: 40,
            marginLeft: 8,
            marginTop: 8
        },
    },

    customHoverButton: {
        backgroundColor: Colors.Secondary5,

        '&:hover': {
            backgroundColor: Colors.Secondary5
        }
    }
}));

export const isOnnet = (value: string) => {
    if(!value?.length) return false;

    return /^[0-9]{1,5}$/.test(value);
}