import {
    AddtoRingGroupFormProps,
    DispatchType,
} from '../../components/Extensions/RingGroupsInExtension/AddToRingGroupDialog.utils';
import { AssignedExtension, RingGroupType } from '../../store/types/RingGroup';

export const prepareInitialValues = (
    ringGroupToEdit: RingGroupType,
    assignedExtension?: AssignedExtension,
) => {
    return {
        ignoreFollowMeVoiceMail: assignedExtension?.huntstop === 'Y',
        setAsPrimary: assignedExtension?.primary_group === 'Y',
        groupId: ringGroupToEdit.id,
        dispatchType:
            assignedExtension?.hunt_active === 'N'
                ? DispatchType.Disabled
                : assignedExtension?.hunt_expire === '' ||
                  assignedExtension?.hunt_expire === undefined ||
                  assignedExtension?.hunt_delay === '' ||
                  assignedExtension?.hunt_delay === undefined
                ? DispatchType.PermamentlyEnabled
                : DispatchType.Custom,
        customWaitsFor:
            assignedExtension?.hunt_delay === '' ||
            assignedExtension?.hunt_delay === undefined
                ? 15
                : assignedExtension?.hunt_delay,
        customThenRingsFor:
            assignedExtension?.hunt_expire === '' ||
            assignedExtension?.hunt_expire === undefined
                ? 30
                : assignedExtension?.hunt_expire,
        supervisor: assignedExtension?.supervision_allowed === 'Y',
        allowGroupPaging: assignedExtension?.paging_allowed === 'Y',
        ringing: assignedExtension?.hunt_active !== 'N',
    };
};

export const prepareAssignedExtensionToChange: (
    form: AddtoRingGroupFormProps,
) => AssignedExtension = (form) => ({
    hunt_active: form.ringing ? 'Y' : 'N',
    hunt_delay:
        form.dispatchType === DispatchType.Custom ? form.customWaitsFor : '',
    hunt_expire:
        form.dispatchType === DispatchType.Custom
            ? form.customThenRingsFor
            : '',
    huntstop: form.ignoreFollowMeVoiceMail ? 'Y' : 'N',
    primary_group: form.setAsPrimary ? 'Y' : 'N',
    supervision_allowed: form.supervisor ? 'Y' : 'N',
    paging_allowed: form.allowGroupPaging ? 'Y' : 'N',
});
