import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../styles/Colors';
import * as Yup from 'yup';
import {
    AutoAttendantMenu,
    MenuAction,
} from '../../../store/types/AutoAttendant';
import { CallQueue } from '../../../store/types/CallQueue';
import i18n from '../../../services/i18n';

export type SettingsFormType = {
    menuId: number;
    name: string;
    inactiveMenuAction?: MenuAction;
    playBeforeAction: boolean;
    recordBeforeActionName: string;
    recordBeforeActionFile: File | null;
    queue?: CallQueue;
    menu?: AutoAttendantMenu;
    transferDestination?: string;
    allowCallersToDialKnownNumber: boolean;
    callersToDialKnownNumberTimeout: string;
    maxDigits?: string;
    errorsCount: string;
};

export const requiredError = i18n.t<string>('errors:common.emptyInput');
const transferDestinationValidation = i18n.t(
    'errors:extensions.transferDestination',
);
const number = i18n.t('errors:extensions.number');

export const settingsFormValidationSchema = Yup.object().shape({
    name: Yup.string().required(requiredError),
    inactiveMenuAction: Yup.string().required(),
    playBeforeAction: Yup.boolean().required(),
    recordBeforeActionName: Yup.string().notRequired(),
    allowCallersToDialKnownNumber: Yup.boolean().required(),
    callersToDialKnownNumberTimeout: Yup.string().notRequired(),
    errorsCount: Yup.string().notRequired(),
    menu: Yup.object().when('inactiveMenuAction', {
        is: (value: unknown) => value === MenuAction.Menu,
        then: Yup.object().required(requiredError),
        otherwise: Yup.object(),
    }),
    queue: Yup.object().when('inactiveMenuAction', {
        is: (value: unknown) => value === MenuAction.Queue,
        then: Yup.object().required(requiredError),
        otherwise: Yup.object(),
    }),
    transferDestination: Yup.string()
        .when('inactiveMenuAction', {
            is: (value: unknown) =>
                value === MenuAction.Transfer ||
                value === MenuAction.TransferToE164Number,
            then: Yup.string().required(requiredError),
            otherwise: Yup.string(),
        })
        .when('inactiveMenuAction', {
            is: (value: unknown) => value === MenuAction.TransferToE164Number,
            then: Yup.string()
                .matches(/^[0-9*#]+$/, i18n.t<string>(number))
                .min(7, transferDestinationValidation)
                .required(requiredError),
            otherwise: Yup.string(),
        }),
    maxDigits: Yup.string(),
});

export const settingsFormDefaultValues: SettingsFormType = {
    menuId: -1,
    name: '',
    inactiveMenuAction: MenuAction.DoNothing,
    playBeforeAction: false,
    recordBeforeActionName: '',
    recordBeforeActionFile: null,
    allowCallersToDialKnownNumber: false,
    callersToDialKnownNumberTimeout: '0',
    errorsCount: '0',
    transferDestination: '',
    maxDigits: '4',
};

export type SettingsFormProps = {};

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '0 16px',
        marginTop: 7,
        maxWidth: 976,
        display: 'flex',
        flexDirection: 'column',
    },
    intervalsContainer: {
        marginBottom: 20,
    },
    callerContainer: {
        marginBottom: 15,
    },
    inputs: {
        display: 'flex',

        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
    },
    nameInput: {
        width: 360,
        marginBottom: 20,
    },
    padding: {
        '& .MuiBox-root': {
            paddingLeft: 0,
        },
    },
    file: {
        '& .MuiGrid-root': {
            maxWidth: 426,
            marginBottom: 6,
        },
        '& .MuiButtonBase-root': {
            width: 90,
        },
    },
    playBeforeAction: {
        color: Colors.Gray7,
    },
    noBorder: {
        borderBottom: 'none',
    },
    separator: {
        height: 1,
        width: 944,
        backgroundColor: Colors.Border,
        margin: '5px 0',
    },
}));
