import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    notSupportedMainContainer: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        background: 'white',
    },

    notSupported: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 12,
        padding: 12,
        background: 'rgba(0, 0, 0, 0.87)',
        borderRadius: 4,
        width: 'fit-content',
        height: 'fit-content',
        marginTop: 'calc(50vh - 60px)',
        marginLeft: 'calc(50vw - 150px)',
        maxWidth: 300
    },
    
    notSupportedText: {
        color: '#F5F7F8'
    }
}));

const NotSupportedBrowser = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.notSupportedMainContainer}>
            <div className={classes.notSupported}>
                <div className={classes.notSupportedText}>{t('common:browserVersionIsTooOld')}</div>
            </div>
        </div>
    );
};

/**
 * To update the regex expression, execute the next command in Terminal:
 * npx browserslist-useragent-regexp --allowHigherVersions
 */
export const isBrowserSupported = (browser: string) => {
    const regex = /(Edge?\/(104(\.0|)|104(\.([1-9]|\d{2,})|)|(10[5-9]|1[1-9]\d|[2-9]\d\d|\d{4,})(\.\d+|))(\.\d+|))|(Firefox\/(104\.0|104\.([1-9]|\d{2,})|(10[5-9]|1[1-9]\d|[2-9]\d\d|\d{4,})\.\d+)(\.\d+|))|(Chrom(ium|e)\/(102\.0|102\.([1-9]|\d{2,})|(10[3-9]|1[1-9]\d|[2-9]\d\d|\d{4,})\.\d+)(\.\d+|))|(Maci.* Version\/(13\.1|13\.([2-9]|\d{2,})|(1[4-9]|[2-9]\d|\d{3,})\.\d+|15\.5|15\.([6-9]|\d{2,})|(1[6-9]|[2-9]\d|\d{3,})\.\d+)([.,]\d+|)( Mobile\/\w+|) Safari\/)|(Chrome.+OPR\/(90\.0|90\.([1-9]|\d{2,})|(9[1-9]|\d{3,})\.\d+)\.\d+)|((CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS|CPU iPad OS)[ +]+(12[_.]2|12[_.]([3-9]|\d{2,})|12[_.]5|12[_.]([6-9]|\d{2,})|(1[3-9]|[2-9]\d|\d{3,})[_.]\d+|14[_.]0|14[_.]([1-9]|\d{2,})|14[_.]4|14[_.]([5-9]|\d{2,})|14[_.]8|14[_.](9|\d{2,})|(1[5-9]|[2-9]\d|\d{3,})[_.]\d+|15[_.]0|15[_.]([1-9]|\d{2,})|(1[6-9]|[2-9]\d|\d{3,})[_.]\d+|16[_.]0|16[_.]([1-9]|\d{2,})|(1[7-9]|[2-9]\d|\d{3,})[_.]\d+)([_.]\d+|))|(Android:?[ \-\/](4(\.4|)|4(\.([5-9]|\d{2,})|)|([5-9]|\d{2,})(\.\d+|))(\.\d+|))|(Android.+Firefox\/(105\.0|105\.([1-9]|\d{2,})|(10[6-9]|1[1-9]\d|[2-9]\d\d|\d{4,})\.\d+)(\.\d+|))|(Android.+Chrom(ium|e)\/(106\.0|106\.([1-9]|\d{2,})|(10[7-9]|1[1-9]\d|[2-9]\d\d|\d{4,})\.\d+)(\.\d+|))|(Android.+(UC? ?Browser|UCWEB|U3)[ \/]?(13\.4|13\.([5-9]|\d{2,})|(1[4-9]|[2-9]\d|\d{3,})\.\d+)\.\d+)|(SamsungBrowser\/(17\.0|17\.([1-9]|\d{2,})|(1[8-9]|[2-9]\d|\d{3,})\.\d+))/;
    return regex.test(browser);
}

export default NotSupportedBrowser;
