/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Cell, Column, ColumnInstance, Row } from 'react-table';

export type RowData<T extends Object> = {
    [key: string]: any;
} & T;

export enum PaginationMode {
    Client = 'client',
    Server = 'server',
}

export type PageChangeEvent = {
    page: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
    paginationMode: PaginationMode;
};

export type DataGridProps<T extends Object> = {
    columns: Column<RowData<T>>[];
    data: RowData<T>[];
    rowCount: number;

    loading?: boolean;
    showMessageWhenEmpty?: boolean;

    hidePagination?: boolean;
    paginationMode?: PaginationMode;
    pageSizeOptions?: number[];
    initialPageSize?: number;
    hideHeader?: boolean;
    hideSeparator?: boolean;
    hideFooter?: boolean;
    customRowHeight?: number;

    dataQa?: string;

    className?: string;
    classes?: { pagination?: string; tableContainer?: string; header?: string };
    centeredRows?: boolean;
    narrowRows?: boolean;

    dragEnabled?: boolean;
    multiSelectEnabled?: boolean;

    onPageChange?: (param: PageChangeEvent) => void;
    onPageSizeChange?: (param: PageChangeEvent) => void;

    onChangeItemPosition?: (startIndex: number, endIndex: number) => void;
    onChangeItemsSelection?: (items: RowData<T>[]) => any;

    onCellHover?: (cell: Cell<RowData<T>>) => void;

    getItemId?: (item: RowData<T>, index: number) => number | string;
    renderRowHeader?: (row: Row<RowData<T>>, index: number) => JSX.Element;
    forceFirstPage?: boolean;
    dragHeader?: string;
    dragColumnStyle?: string;
    dragHeaderStyle?: string;
    message?:string;
    showNoDataImg?:boolean;
    showNoDataMsg?:boolean;
};

export type EmptyGridPlaceholderProps = {
    message?: string;
    isVisible?: boolean;
    showNoDataImg?:boolean
};

export type RowCellProps<T extends Object> = {
    cell: Cell<RowData<T>>;
    onCellHover?: (cell: Cell<RowData<T>>) => void;
    rowIndex: number;
    colIndex: number;
};

export type RowProps<T extends Object> = {
    row: Row<RowData<T>>;
    index: number;
    dragEnabled?: boolean;
    onCellHover?: (cell: Cell<RowData<T>>) => void;
    renderRowHeader?: (row: Row<RowData<T>>, index: number) => JSX.Element;
    customRowHeight?: number;
    dragColumnStyle?: string;
};

export type HeaderRowProps<T extends Object> = {
    isVisible?: boolean;
    columns: ColumnInstance<RowData<T>>[];
    dragEnabled?: boolean;
    dragHeader?: string;
    dragHeaderStyle?: string;
};

export type PaginationProps = {
    isVisible?: boolean;
    className?: string;
    paginationMode?: PaginationMode;
    pageSizeOptions?: number[];
    pageSize: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        page: number,
    ) => void;
    onPageSizeChange?: React.ChangeEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    >;
    rowCount: number;
    page: number;
};
