import { createAction, createAsyncAction } from 'typesafe-actions';
import { APIErrorInterface } from '../../types';
import { AccountFollowMeSettings } from '../../types/AccountFollowMeSettings';
import { CustomerDIDNumberType } from '../../types/CustomerDIDNumber';
import { CustomerInfo } from '../../types/CustomerInfo';
import {
    CustomerExtension,
    DeleteCustomerExtension,
    ExtensionType,
} from '../../types/Extension';
import { PermittedSipProxy } from '../../types/SipProxies';

import * as payloads from './payloads';
import { ExtensionsListItem } from '../../reducers/extensions/extensions/reducer';
import { VoicemailGreeting, VoicemailSettings } from '../../types/Voicemail';
import { AddonsValidationResults } from '../../types/CustomerAgreementCondition';
import { ServiceFeature } from '../../types/ServiceFeature';
import { DialingRuleInfo } from '../../types/DialingRule';
import { GetCallProcessingPolicyListEntity, OperationMode } from './payloads';
import { CPRuleInfo } from '../../types/CallScreening';

export const getExtensionById = createAsyncAction(
    'GET_EXTENSION_BY_ID',
    'GET_EXTENSION_BY_ID_SUCCESS',
    'GET_EXTENSION_BY_ID_FAILED',
)<{ id: string }, { extension?: ExtensionsListItem }, undefined>();

export const getExtensionsList = createAsyncAction(
    'GET_EXTENSION_LIST',
    'GET_EXTENSION_LIST_SUCCESSED',
    'GET_EXTENSION_LIST_FAILED',
)<
    payloads.ExtensionsListRequestPayload,
    payloads.ExtensionListResponsePayload,
    APIErrorInterface
>();

export const getDIDNumbersForExtension = createAsyncAction(
    'GET_EXTENSION_DID_NUMBERS',
    'GET_EXTENSION_DID_NUMBERS_SUCCESS',
    'GET_EXTENSION_DID_NUMBERS_FAILED',
)<
    { i_account: number },
    {
        i_account: number;
        numbers: CustomerDIDNumberType[];
    },
    { i_account: number }
>();

export const getAllExtensionsList = createAsyncAction(
    'GET_ALL_EXTENSION_LIST',
    'GET_ALL_EXTENSION_LIST_SUCCESS',
    'GET_ALL_EXTENSION_LIST_FAILED',
)<
    payloads.ExtensionsListRequestPayload,
    payloads.ExtensionListResponsePayload,
    APIErrorInterface
>();

export const getExtensionIncomingDetails = createAsyncAction(
    'GET_EXTENSION_INCOMING_DETAILS',
    'GET_EXTENSION_INCOMING_DETAILS_SUCCESSED',
    'GET_EXTENSION_INCOMING_DETAILS_FAILED',
)<
    payloads.ExtensionIncomingDetailsRequestPayload,
    payloads.ExtensionIncomingDetailsResponsePayload,
    APIErrorInterface
>();

export const resetExtensionRingGroups = createAction(
    'RESET_EXTENSION_RING_GROUP',
)();

export const getExtensionRingGroups = createAsyncAction(
    'GET_EXTENSION_RING_GROUPS',
    'GET_EXTENSION_RING_GROUPS_SUCCESSED',
    'GET_EXTENSION_RING_GROUPS_FAILED',
)<
    payloads.ExtensionRingGroupsRequestPayload,
    payloads.ExtensionRingGroupsResponsePayload,
    Error
>();

export const getExtensionMohDetails = createAsyncAction(
    'GET_EXTENSION_MOH_DETAILS',
    'GET_EXTENSION_MOH_DETAILS_SUCCESSED',
    'GET_EXTENSION_MOH_DETAILS_FAILED',
)<
    payloads.ExtensionMohDetailsRequestPayload,
    payloads.ExtensionMohDetailsResponsePayload,
    Error
>();

export const getCustomerExtensionDetails = createAsyncAction(
    'GET_CUSTOMER_EXTENSION_DETAILS',
    'GET_CUSTOMER_EXTENSION_DETAILS_SUCCESSED',
    'GET_CUSTOMER_EXTENSION_DETAILS_FAILED',
)<payloads.CustomerExtensionDetailsRequestPayload, CustomerExtension, Error>();

export const getExtensionFollowMeSettings = createAsyncAction(
    'GET_EXTENSION_ACCOUNT_FOLLOWME_SETTINGS',
    'GET_EXTENSION_ACCOUNT_FOLLOWME_SETTINGS_SUCCESSED',
    'GET_EXTENSION_ACCOUNT_FOLLOWME_SETTINGS_FAILED',
)<
    payloads.ExtensionFollowMeSettingsRequestPayload,
    AccountFollowMeSettings,
    APIErrorInterface | undefined
>();

export const getCustomerInfoDetails = createAsyncAction(
    'GET_CUSTOMER_INFO_DETAILS',
    'GET_CUSTOMER_INFO_DETAILS_SUCCESSED',
    'GET_CUSTOMER_INFO_DETAILS_FAILED',
)<undefined, CustomerInfo & { didNumbers: CustomerDIDNumberType[] }, Error>();

export const setExtensionStatus = createAsyncAction(
    'SET_EXTENSION_STATUS',
    'SET_EXTENSION_STATUS_SUCCEEDED',
    'SET_EXTENSION_STATUS_FAILED',
)<
    payloads.UpdateExtensionStatusPayload,
    payloads.UpdateExtensionStatusPayload,
    Error
>();

export const editExtension = createAsyncAction(
    'EDIT_EXTENSION',
    'EDIT_EXTENSION_SUCCEEDED',
    'EDIT_EXTENSION_FAILED',
)<payloads.EditExtensionRequestPayload, undefined, APIErrorInterface>();

export const uploadMohFile = createAsyncAction(
    'UPLOAD_MOH_FILE',
    'UPLOAD_MOH_FILE_SUCCESSED',
    'UPLOAD_MOH_FILE_FAILED',
)<payloads.UploadMohFileRequestPayload, undefined, Error>();

export const getPermittedSipProxies = createAsyncAction(
    'GET_PERMITTED_SIP_PROXIES',
    'GET_PERMITTED_SIP_PROXIES_SUCCESSED',
    'GET_PERMITTED_SIP_PROXIES_FAILED',
)<{ i_account: number }, PermittedSipProxy[], APIErrorInterface | undefined>();

export const getCallScreeningRules = createAsyncAction(
    'GET_CALL_SCREENING_RULES',
    'GET_CALL_SCREENING_RULES_SUCCESS',
    'GET_CALL_SCREENING_RULES_FAILED',
)<payloads.GetCPRuleListRequest, payloads.GetCPRuleListResponse, Error>();

export const getCallScreeningConditions = createAsyncAction(
    'GET_CALL_SCREENING_CONDITION',
    'GET_CALL_SCREENING_CONDITION_SUCCESS',
    'GET_CALL_SCREENING_CONDITION_FAILED',
)<
    payloads.GetCPConditionListRequestPayload,
    payloads.GetCPConditionListResponsePayload,
    APIErrorInterface | undefined
>();

export const getCallProcessingPolicyList = createAsyncAction(
    'GET_CALL_PROCESSING_POLICY_LIST',
    'GET_CALL_PROCESSING_POLICY_LIST_SUCCESS',
    'GET_CALL_PROCESSING_POLICY_LIST_FAILED',
)<
    payloads.GetCallProcessingPolicyListRequestPayload,
    GetCallProcessingPolicyListEntity[],
    undefined
>();

export const getCallBarringRules = createAsyncAction(
    'GET_CALL_BARRING_RULES',
    'GET_CALL_BARRING_RULES_SUCCESS',
    'GET_CALL_BARRING_RULES_FAILED',
)<
    payloads.GetCallBarringRulesRequest,
    payloads.GetCallBarringRulesResponse,
    Error
>();

export const getExtensionDetailsData = createAsyncAction(
    'GET_EXTENSION_DETAILS_DATA',
    'GET_EXTENSION_DETAILS_DATA_SUCCESS',
    'GET_EXTENSION_DETAILS_DATA_FAILED',
)<payloads.GetExtensionDetailsDataRequestPayload, undefined, undefined>();

export const resetExtensionBasicDetails = createAction(
    'RESET_EXTENSION_BASIC_DETAILS',
)();

export const getExtensionBasicDetailsData = createAsyncAction(
    'GET_EXTENSION_BASIC_DETAILS_DATA',
    'GET_EXTENSION_BASIC_DETAILS_DATA_SUCCESS',
    'GET_EXTENSION_BASIC_DETAILS_DATA_FAILED',
)<
    payloads.GetExtensionDetailsDataRequestPayload,
    ExtensionsListItem,
    APIErrorInterface | undefined
>();

export const getExtensionTabData = createAsyncAction(
    'GET_EXTENSION_TAB_DETAILS_DATA',
    'GET_EXTENSION_TAB_DETAILS_DATA_SUCCESS',
    'GET_EXTENSION_TAB_DETAILS_DATA_FAILED',
)<payloads.GetExtensionTabDataPayload, undefined, undefined>();

export const getCallScreeningTabData = createAsyncAction(
    'GET_CALL_SCREENING_TAB_DETAILS_DATA',
    'GET_CALL_SCREENING_TAB_DETAILS_DATA_SUCCESS',
    'GET_CALL_SCREENING_TAB_DETAILS_DATA_FAILED',
)<
    payloads.GetCPRuleListRequest &
        payloads.GetCallProcessingPolicyListRequestPayload,
    undefined,
    undefined
>();

export const getCallSettingsTabData = createAsyncAction(
    'GET_CALL_SETTINGS_TAB_DATA',
    'GET_CALL_SETTINGS_TAB_DATA_SUCCESS',
    'GET_CALL_SETTINGS_TAB_DATA_FAILED',
)<{ id: string; domain: string; i_account: number }, undefined, undefined>();

export const getServiceFeatures = createAsyncAction(
    'GET_SERVICE_FEATURES',
    'GET_SERVICE_FEATURES_SUCCESS',
    'GET_SERVICE_FEATURES_FAILED',
)<{ i_account: number }, ServiceFeature[], undefined>();

export const getPlanTabData = createAsyncAction(
    'GET_PLAN_TAB_DATA',
    'GET_PLAN_TAB_DATA_SUCCESS',
    'GET_PLAN_TAB_DATA_FAILED',
)<{ i_account: number }, undefined, undefined>();

export const getDeviceTabData = createAsyncAction(
    'GET_DEVICE_TAB_DATA',
    'GET_DEVICE_TAB_DATA_SUCCESS',
    'GET_DEVICE_TAB_DATA_FAILED',
)<{ i_account: number }, undefined, undefined>();

export const getCallForwardingTabData = createAsyncAction(
    'GET_CALL_FORWARDING_TAB_DATA',
    'GET_CALL_FORWARDING_TAB_DATA_SUCCESS',
    'GET_CALL_FORWARDING_TAB_DATA_FAILED',
)<{ i_account: number }, undefined, undefined>();

export const refreshExtensionStatus = createAsyncAction(
    'REFRESH_EXTENSION_STATUS',
    'REFRESH_EXTENSION_STATUS_SUCCESS',
    'REFRESH_EXTENSION_STATUS_FAILED',
)<
    payloads.RefreshExtensionStatusRequestPayload,
    payloads.RefreshExtensionStatusResponsePayload,
    undefined
>();

export const getAccountInfo = createAsyncAction(
    'GET_ACCOUNT_INFO',
    'GET_ACCOUNT_INFO_SUCCESS',
    'GET_ACCOUNT_INFO_FAILED',
)<{ i_account: number }, ExtensionType, undefined>();

export const getExtensionItemDetails = createAsyncAction(
    'GET_EXTENSION_ITEM_DETAILS',
    'GET_EXTENSION_ITEM_DETAILS_SUCCESS',
    'GET_EXTENSION_ITEM_DETAILS_FAILED',
)<{ extensionID: string }, ExtensionsListItem, undefined>();

export const getExtensionProducts = createAsyncAction(
    'GET_EXTENSION_PRODUCTS',
    'GET_EXTENSION_PRODUCTS_SUCCESS',
    'GET_EXTENSION_PRODUCTS_FAILED',
)<undefined, payloads.GetExtensionProductsResponsePayload, undefined>();

export const getExtensionVoicemailSettings = createAsyncAction(
    'GET_EXTENSION_VOICEMAIL_SETTINGS',
    'GET_EXTENSION_VOICEMAIL_SETTINGS_SUCCESS',
    'GET_EXTENSION_VOICEMAIL_SETTINGS_FAILED',
)<
    payloads.GetExtensionVoicemailSettingsRequestPayload,
    VoicemailSettings,
    undefined
>();

export const getGreetingsFilesNames = createAsyncAction(
    'GET_GREETINGS_FILES_NAMES',
    'GET_GREETINGS_FILES_NAMES_SUCCESS',
    'GET_GREETINGS_FILES_NAMES_FAILED',
)<
    payloads.GetExtensionVoicemailSettingsRequestPayload,
    { [key in VoicemailGreeting]?: string },
    undefined
>();

export const deleteCustomerExtension = createAsyncAction(
    'DELETE_CUSTOMER_EXTENSION',
    'DELETE_CUSTOMER_EXTENSION_SUCCESS',
    'DELETE_CUSTOMER_EXTENSION_FAILED',
)<DeleteCustomerExtension, { id: string }, undefined>();

export const deleteCustomerExtensionDetailsView = createAsyncAction(
    'DELETE_CUSTOMER_EXTENSION_DETAILS_VIEW',
    'DELETE_CUSTOMER_EXTENSION_DETAILS_VIEW_SUCCESS',
    'DELETE_CUSTOMER_EXTENSION_DETAILS_VIEW_FAILED',
)<DeleteCustomerExtension, undefined, undefined>();

export const validateAddonsProducts = createAsyncAction(
    'VALIDATE_ADDONS_PRODUCTS',
    'VALIDATE_ADDONS_PRODUCTS_SUCCESS',
    'VALIDATE_ADDONS_PRODUCTS_FAILED',
)<
    payloads.ValidateAddonsProductsRequestPayload,
    AddonsValidationResults,
    undefined
>();

export const createExtensionDetailsData = createAsyncAction(
    'CREATE_EXTENSION_DETAILS_DATA',
    'CREATE_EXTENSION_DETAILS_DATA_SUCCESS',
    'CREATE_EXTENSION_DETAILS_DATA_FAILED',
)<{ i_account?: number } | undefined, undefined, undefined>();

export const createNewExtension = createAsyncAction(
    'CREATE_NEW_EXTENSION',
    'CREATE_NEW_EXTENSION_SUCCESS',
    'CREATE_NEW_EXTENSION_FAILED',
)<payloads.CreateNewExtensionRequestPayload, undefined, APIErrorInterface>();

export const getExtensionDialingRules = createAsyncAction(
    'GET_EXTENSION_DIALIG_RULES',
    'GET_EXTENSION_DIALIG_RULES_SUCCESS',
    'GET_EXTENSION_DIALIG_RULES_FAILED',
)<{ i_dial_rule: string }, DialingRuleInfo, APIErrorInterface>();

export const fetchDataForSwitchMode = createAsyncAction(
    'FETCH_DATA_SWITCH_MODE_DIALOG',
    'FETCH_DATA_SWITCH_MODE_DIALOG_SUCCESS',
    'FETCH_DATA_SWITCH_MODE_DIALOG_FAILED',
)<undefined, undefined, undefined>();

export const getCallProcessingOperationModeList = createAsyncAction(
    'FETCH_CALL_PROCESSING_OPERATION_MODE',
    'FETCH_CALL_PROCESSING_OPERATION_MODE_SUCCESS',
    'FETCH_CALL_PROCESSING_OPERATION_MODE_FAILED',
)<payloads.CallProcessingOperationModeListPayload, OperationMode[], undefined>();

export const updateAccountsCallProcessingMode = createAsyncAction(
    'UPDATE_ACCOUNTS_CALL_PROCESSING_MODE',
    'UPDATE_ACCOUNTS_CALL_PROCESSING_MODE_SUCCESS',
    'UPDATE_ACCOUNTS_CALL_PROCESSING_MODE_FAILED',
)<payloads.SwitchModeRequestPayload, undefined, undefined>();

export const getCpRuleList = createAsyncAction(
    'GET_CP_RULE_LIST',
    'GET_CP_RULE_LIST_SUCCESS',
    'GET_CP_RULE_LIST_FAILED',
)<payloads.GetCPRuleListRequest, CPRuleInfo[], APIErrorInterface | undefined>();

export const clearErrorsCreateNewExtensionForm = createAction(
    'CLEAR_ERRORS_ADD_NEW_EXTENSION_FORM',
)();
