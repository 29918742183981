import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../styles/Colors";
import Config from '../../config.json';

const useStyle = makeStyles(() => {
    return {
        root: {
            fontSize: 16,
            fontWeight: 400,
            color: Colors.Gray9
        }
    }
})

const DEFAULT_PATH = './commit'

const PortalVersion = () => {

    const {t} = useTranslation();
    const [tag, setTag] = useState<string|undefined>(undefined);
    const [url, setUrl] = useState<string>("");
    const classes = useStyle();
    // @ts-ignore
    const customPath = Config.COMMIT_FILE_PATH;
    const commitPath = customPath == undefined || customPath == '$COMMIT_FILE_PATH' ? DEFAULT_PATH : customPath;

    useEffect(() => {
        fetch(commitPath)
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                if (data.tag) {
                    setTag(data.tag)
                }

                if (data.url) {
                    setUrl(data.url)
                }
            })
            .catch(e => setTag(undefined))
    }, []);

    const renderedTag = <span className={classes.root}>{t('common:version', {tag: tag})}</span>;

    if(tag && url.length > 0)
    {
        return <a href={url}>{renderedTag}</a>
    }else if(tag)
    {
        return <>{renderedTag}</>
    }

    return null;

}

export default PortalVersion;