import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { callQueueFormDefaultValues } from '../components/Forms/RingGroups/CallQueueForm';
import { ringGroupGeneralFormDefaultValues } from '../components/Forms/RingGroups/GeneralForm';
import { groupMembersDefaultValues } from '../components/Forms/RingGroups/GroupMembersForm';
import {
    DispatchType,
    GroupMemberType,
} from '../components/RingGroups/groupDialogUtils';
import { ReduxState } from '../store/types';
import {
    AssignedExtension,
    CallerID,
    RingGroupType,
    RingStrategy,
} from '../store/types/RingGroup';

import { EditRingGroupForm } from '../views/RingGroups/RingGroupDetails.utils';

const defaultValues = {
    ...ringGroupGeneralFormDefaultValues,
    ...groupMembersDefaultValues,
    ...callQueueFormDefaultValues,
};

export const useEditRingGroupFormData = () => {
    const details = useSelector<ReduxState, RingGroupType | undefined>(
        (state) => state.ringgroups.ringGroupDetails,
    );

    const initialValues = useMemo((): EditRingGroupForm => {
        if (!details) {
            return defaultValues;
        }

        return {
            name: details.name,
            number: details.id,
            callerId: details.hunt_keep_original_cli as CallerID,
            ringbackTone:
                details.i_ringback_tone !== undefined
                    ? details.i_ringback_tone
                    : null,
            callPickupAllowed: details.pickup_allowed === 'Y',
            ringStrategy: details.hunt_sequence as RingStrategy,
            members: (details.assigned_extensions as AssignedExtension[])
                .sort((v, w) =>
                    (v.hunt_order || 0) > (w.hunt_order || 0) ? 1 : -1,
                )
                .map((v) => ({
                    id: v.id,
                    ignoreFollowMeVoiceMail: v.huntstop === 'Y',
                    setAsPrimary: v.primary_group === 'Y',
                    dispatchType:
                        v.hunt_active === 'Y'
                            ? v.hunt_delay !== undefined
                                ? DispatchType.Custom
                                : DispatchType.PermamentlyEnabled
                            : DispatchType.Disabled,
                    customWaitsFor: v.hunt_delay ?? 15,
                    customThenRingsFor: v.hunt_expire ?? 15,
                    groupMemberType:
                        v.type === 'Account'
                            ? GroupMemberType.Extension
                            : GroupMemberType.RingGroup,
                    ringGroup:
                        v.type === 'Account'
                            ? undefined
                            : {
                                i_c_group: v.i_c_group,
                                name: v.name,
                                id: v.id,
                                i_c_ext: v.i_c_ext,
                            },
                    extension:
                        v.type === 'Account'
                            ? {
                                i_c_group: v.i_c_group,
                                name: v.name,
                                id: v.id,
                                i_c_ext: v.i_c_ext,
                            }
                            : undefined,
                    supervisionAllowed: v.supervision_allowed,
                    member: {
                        i_c_ext: undefined,
                        i_c_group: undefined,
                        id: undefined,
                        name: undefined,
                    },
                    allowGroupPaging: v.paging_allowed === 'Y'
                })),
            callQueueStatus: !!details.assigned_callqueue,
            announceNumberOfCallersInQueue:
                details.assigned_callqueue?.announce_number_of_callers === 'Y',
            maximumNumberOfQueuedCallers:
                details.assigned_callqueue?.incoming_capacity?.toString() ||
                defaultValues.maximumNumberOfQueuedCallers,
            announceEstimatedWaitTime:
                details.assigned_callqueue?.announce_estimated_wait_time ===
                'Y',
            averageHandleTime:
                details.assigned_callqueue?.average_duration?.toString() ||
                defaultValues.averageHandleTime,
            intervalBetweenAnnouncements:
                details.assigned_callqueue?.announcement_interval?.toString() ||
                defaultValues.averageHandleTime,
            playOnHoldMusicStatus: !!details.assigned_callqueue?.moh_file_name,
            onHoldMusicFileName: details.assigned_callqueue?.moh_file_name,
            onHoldMusicFile: null,
        };
    }, [details]);

    return {
        initialValues,
        data: {
            details,
        },
    };
};
