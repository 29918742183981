import {
    DispatchType,
    GroupMemberType,
    RingGroupMemberFormProps,
} from '../../components/RingGroups/groupDialogUtils';
import { AssignedExtension } from '../../store/types/RingGroup';

export const prepareRingGroupMemberData = (
    v: RingGroupMemberFormProps,
): Partial<AssignedExtension> => ({
    id:
        v.groupMemberType === GroupMemberType.Extension
            ? v.extension?.id
            : v.ringGroup?.id,
    name:
        v.groupMemberType === GroupMemberType.Extension
            ? v.extension?.name
            : v.ringGroup?.name,
    account_id: v.extension?.account_id,
    i_c_ext:
        v.groupMemberType === GroupMemberType.Extension
            ? v.extension?.i_c_ext
            : v.ringGroup?.i_c_ext,
    i_c_group: v.ringGroup?.i_c_group,
    hunt_active:
        v.dispatchType === DispatchType.PermamentlyEnabled ||
        v.dispatchType === DispatchType.Custom
            ? 'Y'
            : 'N',
    hunt_delay:
        v.dispatchType === DispatchType.Custom ? v.customWaitsFor : null,
    hunt_expire:
        v.dispatchType === DispatchType.Custom ? v.customThenRingsFor : null,
    huntstop: v.ignoreFollowMeVoiceMail ? 'Y' : 'N',
    primary_group: v.setAsPrimary ? 'Y' : 'N',
    supervision_allowed: v.supervisionAllowed,
    paging_allowed: v.allowGroupPaging ? 'Y' : 'N',
});
