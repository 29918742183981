import React, {useEffect, useMemo, useState} from 'react';
import Header from '../../../components/ListViewHeader/Header';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {actions} from '../../../store';
import {ReduxState} from '../../../store/types';
import DataGrid from '../../../components/DataGrid/DataGrid';
import {PaginationMode} from '../../../components/DataGrid/types';
import {AutoAttendantListItem} from '../../../store/types/AutoAttendant';
import CallHistoryDialog from '../../../components/Extensions/CallHistoryDialog';
import Dialog from '../../../components/AutoAttendants/CreateNewAutoAttendant/Dialog';
import {generateColumns, useStyles} from './utils';
import AlertDialog from '../../../components/AlertDialog/AlertDialog';
import {DialogButton} from '../../../components/AlertDialog/DialogContainer';
import usePageTitle from '../../../hooks/usePageTitle';
import PermissionProvider from '../../../components/PermissionProvider/PermissionProvider';
import {Permission} from '../../../store/types/Permission';
import PermissionPlaceholder from "../../../components/PermissionProvider/PermissionPlaceholder";

const AutoAttendantsList = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    usePageTitle();

    const [
        createNewAutoAttendantModalVisible,
        setCreateNewAutoAttendantModalVisible,
    ] = useState(false);

    const [callHistoryModalConfig, setCallHistoryModalConfig] = useState<{ accountId: number; name?: string } | undefined>();
    const [removeModal, setRemoveModal] = useState<{
        isOpen: boolean;
        name?: string;
        id?: string;
        i_customer?: number;
    }>();

    const data = useSelector(
        (state: ReduxState) => state.autoAttendants.autoAttendantList,
    );

    const {deletingInProgress} = useSelector(
        (state: ReduxState) => state.autoAttendants,
    );

    useEffect(() => {
        dispatch(actions.getCallQueues.request());
    }, []);

    useEffect(() => {
        dispatch(actions.getAutoAttendantsList.request());
    }, []);

    const fetchMenu = (id_pin: string, i_account: number) => {
        useEffect(() => {
            dispatch(
                actions.getAutoAttendantMenuList.request({
                    id_pin,
                    i_account,
                })
            );
        }, [dispatch]);
    };
    const showCallHistory = (accountId: number, name?: string) => {
        setCallHistoryModalConfig({accountId, name});
    };

    const showRemoveExtensionModal = (
        id: string,
        i_customer: number,
        i_c_ext?: number,
        name?: string,
    ) => {
        setRemoveModal({
            isOpen: true,
            id,
            name: name,
            i_customer: i_customer,
        });
    };

    const handleStatusChange = () =>
        setCreateNewAutoAttendantModalVisible(true);

    const deleteExtension = () => {
        if (removeModal?.id && removeModal?.i_customer) {
            dispatch(
                actions.deleteCustomerAutoAttendant.request({
                    id: removeModal.id,
                    i_customer: removeModal.i_customer,
                }),
            );

            setRemoveModal({...removeModal, isOpen: false});
        }
    };

    const toggleCreateNewVisibility = () =>
        setCreateNewAutoAttendantModalVisible(
            !createNewAutoAttendantModalVisible,
        );

    const closeRemoveDialog = () =>
        setRemoveModal({
            ...removeModal,
            isOpen: false,
        });

    const resetCallHistoryDialog = () => setCallHistoryModalConfig(undefined);

    const columns = useMemo(
        () =>
            generateColumns(
                t,
                fetchMenu,
                classes,
                showCallHistory,
                showRemoveExtensionModal,
            ),
        [],
    );

    return (
        <PermissionPlaceholder permission={Permission.CloudPBX.AutoAttendants.value}>
            <div className={classes.mainContainer}>
                <div className={classes.scrollable}>
                    <PermissionProvider
                        permission={
                            Permission.CloudPBX.AutoAttendants.AddNewAutoAttendant
                                .value
                        }
                    >
                        <Header
                            title={t('screens:autoAttendants.listTitle')}
                            totalNumber={data?.total || 0}
                            ofNumber={data?.disabled || 0}
                            dataQa={'device-list-view-header'}
                            buttonText={t('common:addNewButton')}
                            ofText={t('screens:autoAttendants.disabled')}
                            buttonOnClick={handleStatusChange}
                            plusIcon={true}
                            customClass={classes.header}
                        />
                    </PermissionProvider>
                    <DataGrid<AutoAttendantListItem>
                        className={classes.table}
                        classes={{tableContainer: classes.tableContainer}}
                        columns={columns}
                        data={data?.items || []}
                        rowCount={data?.total || 0}
                        loading={data?.isLoading || deletingInProgress || false}
                        paginationMode={PaginationMode.Client}
                        centeredRows
                        narrowRows
                        initialPageSize={10}
                    />
                </div>
                <CallHistoryDialog
                    isOpen={!!callHistoryModalConfig}
                    toggleVisibility={resetCallHistoryDialog}
                    accountId={callHistoryModalConfig?.accountId}
                    customHeader={t('screens:autoAttendants.callHistory', {
                        name: callHistoryModalConfig?.name || '',
                    })}
                    downloadRecordingPermission={
                        Permission.CloudPBX.AutoAttendants.ViewCallHistory.DownloadCallRecording.value
                    }
                />

                <Dialog
                    isOpen={createNewAutoAttendantModalVisible}
                    toggleVisibility={toggleCreateNewVisibility}
                />

                <AlertDialog
                    isOpen={!!removeModal?.isOpen}
                    dataQa={'delete-auto-attendant-modal'}
                    contentClass="medium-width-modal-delete"
                    hideHeader={true}
                    description={t('screens:autoAttendants.deleteMsg', {
                        value: removeModal?.name,
                    })}
                    className={classes.deleteDialogButtons}
                    dialogActionsButtons={[
                        <DialogButton
                            key="cancel"
                            label={t('common:cancel')}
                            onClick={closeRemoveDialog}
                        />,
                        <DialogButton
                            key="delete"
                            label={t('common:delete')}
                            onClick={deleteExtension}
                            dataTestId={'auto-attendant-delete-button'}
                        />,
                    ]}
                />
            </div>
        </PermissionPlaceholder>

    );
};

export default AutoAttendantsList;
