import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../styles/Colors';
import * as Yup from 'yup';
import i18n from 'i18next';

const max999 = i18n.t('errors:ringGroups.max999');

export type CallQueueFormType = {
    callQueueStatus: boolean;
    announceNumberOfCallersInQueue: boolean;
    maximumNumberOfQueuedCallers: number | string;
    announceEstimatedWaitTime: boolean;
    averageHandleTime?: string;
    intervalBetweenAnnouncements?: string;
    playOnHoldMusicStatus: boolean;
    onHoldMusicFileName?: string;
    onHoldMusicFile?: File | null;
};

export const callQueueValidationSchema = Yup.object().shape({
    callQueueStatus: Yup.boolean().required(),
    announceNumberOfCallersInQueue: Yup.boolean().required(),
    maximumNumberOfQueuedCallers: Yup.number().max(999, max999).required(),
    announceEstimatedWaitTime: Yup.boolean().required(),
    averageHandleTime: Yup.number().max(999, max999).required(),
    intervalBetweenAnnouncements: Yup.number().max(999, max999).required(),
    playOnHoldMusicStatus: Yup.boolean().required(),
    onHoldMusicFileName: Yup.string().when('playOnHoldMusicStatus', {
        is: (value: boolean) => value,
        then: Yup.string().required(
            i18n.t<string>('errors:ringGroups.musicIsMandatory'),
        ),
        otherwise: Yup.string().notRequired(),
    }),
    onHoldMusicFile: Yup.object().nullable().notRequired(),
});

export type CallQueueFormProps = {};

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        maxWidth: 1044,
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 16px 0px 16px',
    },
    columnItemsContainer: {
        flexDirection: 'column',
        '& .MuiFormControl-root:first-of-type': {
            marginRight: '15px !important',
        },
        padding: '22px 16px 0px 16px',
    },
    inputs: {
        display: 'flex',

        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            flex: 1,
            height: 'auto',
        },
        '& .MuiFormControl-root:first-of-type': {
            marginRight: 26,
        },

        '& .MuiBox-root': {
            padding: '0px 0px 24px 0px',
        },
        '& h3': {
            marginBlockEnd: 0,
            marginTop: 30,
        },

        '& input[type="number"]::-webkit-inner-spin-button': {
            opacity: 1,
            marginRight: 10,
        },

        '& input[type="number"]::-webkit-outside-spin-button': {
            opacity: 1,
            marginRight: 10,
        },
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        maxWidth: 986,
        '& div': {
            height: 37,
            marginBottom: 0,
        },
        marginBottom: 15,
    },
    rowBoxHeader: {
        marginRight: 20,
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.Gray6,
    },
    boldHeader: {
        fontWeight: 700,
    },
    musicRowBox: {
        padding: 0 + '!important',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginTop: 10,

        '& .MuiButton-label': {
            color: Colors.White,
        },

        '& .MuiFormControl-root': {
            flex: 1,
            marginRight: 14,
        },
    },
    checkbox: {
        flexDirection: 'row-reverse',
        marginLeft: 0,
        marginTop: 20,
        marginBottom: 15,
    },
    optionWithMargin: {
        marginLeft: 20,
    },

    numberInput: {
        flex: 1,
        width: 288,
        marginBottom: 24,
    },
    numberLabel: {
        marginLeft: -60,
        marginTop: 18,
    },
    onHoldMusicTitle: {
        marginRight: 35,
    },
    numberInputTooltip: {
        marginRight: 10,
        marginTop: 12,
    },
    minText: {
        position: 'absolute',
        marginTop: 16,
        marginLeft: 50,
        top: 10.5
    },

    noMarginBottom: {
        marginBottom: 0,
    },
    noPaddingTop: {
        paddingTop: 0,
        '& div': {
            paddingTop: 0,
        },
    },

    marginTop: {
        marginTop: 9,
    },

    extraPadding: {
        paddingTop: 22,
    },
    switchMargin: {
        height: 55,
        marginBottom: 8,
    },
    tinyMargin: {
        paddingTop: '0!important',
        paddingBottom: '20px!important',
    },
}));
