import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
    CallSettingsFormProps,
    ExtensionCallSettingsFormType,
    useStyles,
} from './CallSettingsForm.utils';
import SelectField from '../../../SelectField/SelectField';
import { DefaultAnsweringActionFlag } from '../../../../store/types/ServiceFeature';
import TextField from '../../../TextField/TextField';
import SwitchWithLabel from '../../../SwitchWithLabel/SwitchWithLabel';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';
import OnHoldMusic from '../../../OnHoldMusic/OnHoldMusic';
import { actions } from '../../../../store';
import UnifiedMessagingForm from '../UnifiedMessaging/UnifiedMessagingForm';
import CallBarringForm from '../CallBarring/CallBarringForm';
import CallRecordingForm from '../CallRecording/CallRecordingForm';
import classNames from 'classnames';
import Separator from '../../../Separator/Separator';

const CallSettingsForm: React.FC<CallSettingsFormProps> = ({
    handleDirtyChange,
    handleSetSubmitFunc,
    handleSetIsValidFunc,
    i_account,
    mohItems,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        values,
        handleChange,
        setFieldValue,
        handleSubmit,
        dirty,
        isValid,
        errors,
        setFieldError,
    } = useFormikContext<ExtensionCallSettingsFormType>();

    useEffect(() => {
        handleDirtyChange?.('callSettings', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetSubmitFunc?.('callSettings', handleSubmit);
    }, [handleSubmit]);

    useEffect(() => {
        handleSetIsValidFunc?.('callSettings', () => isValid);
    }, [isValid]);

    const defaultAnsweringModes = useMemo(
        () =>
            Object.values(DefaultAnsweringActionFlag).map((v) => ({
                name: t(`enums:defaultAnsweringActionEnum.${v}`),
                value: v,
            })),
        [],
    );

    const onSaveMohFile = useCallback(
        (file, fileName, callback) => {
            if (i_account) {
                dispatch(
                    actions.uploadMohFile.request({
                        accountId: i_account,
                        file: file,
                        name: fileName,
                        callback,
                    }),
                );
            }
        },
        [i_account],
    );

    return (
        <div className={classes.inputs}>
            <Grid item className={classes.itemsContainer}>
                <div className={classes.rowContainer}>
                    <SelectField
                        label={t('screens:extensions.defaultAnsweringMode')}
                        items={defaultAnsweringModes}
                        value={defaultAnsweringModes.find(
                            (v) => v.value === values.defaultAnsweringMode,
                        )}
                        classes={{
                            container: classes.defaultAnswringModeInput,
                        }}
                        dataQa="incomingform-answering-mode-select"
                        onChange={(_, value) => {
                            setFieldValue('defaultAnsweringMode', value.value);
                        }}
                        getOptionLabel={(v: { name: string; value: string }) =>
                            v.name
                        }
                        getOptionSelected={(v: {
                            name: string;
                            value: string;
                        }) => v.value === values.defaultAnsweringMode}
                        disableClearable
                    />

                    <TextField
                        id="defaultAnsweringTimeout"
                        label={t('screens:extensions.timeout')}
                        onChange={handleChange}
                        value={values.defaultAnsweringTimeout?.toString()}
                        type="number"
                        className={classes.numberInput}
                        dataQa="answering-timeout-select"
                        inputProps={{
                            inputProps: {
                                min: 1,
                                max: 999,
                            },
                            pattern: '[0-9]*',
                            startAdornment: (
                                <span className={classes.minText}>sec</span>
                            ),
                        }}
                        widthFromLiableText
                    />
                </div>
            </Grid>
            <Separator />
            <UnifiedMessagingForm />
            <Separator />
            <CallBarringForm />
            <Separator />
            <CallRecordingForm />
            <Separator />
            <OnHoldMusic
                id="onHoldMusicStatus"
                fileSelectId="onHoldMusicName"
                setValue={setFieldValue}
                value={values.onHoldMusicStatus}
                withTooltip={false}
                mohItems={mohItems}
                selectedMohFile={values.onHoldMusicName || ''}
                onSave={onSaveMohFile}
                customTooltip={t('tooltips:ringGroups.onHoldMusic')}
                classes={{
                    container: classes.mohContainer,
                    selectContainer: classes.selectedMohContainer,
                    switcher: classes.musicOnHoldSwitcher
                }}
            />
            <Separator />
            <SwitchWithLabel
                className={classNames(
                    classes.switchContainer,
                    classes.extraPadding,
                )}
                id="extToExtCallDisctintiveRingStatus"
                field="extToExtCallDisctintiveRingStatus"
                label={t('screens:extensions.extToExtCallDisctintiveRing')}
                value={values.extToExtCallDisctintiveRingStatus}
                disabled={values.extToExtCallDisctintiveRingBlocked}
                setValue={setFieldValue}
                icon={
                    <IconWithTooltip
                        dataQa="extToExtCallDisctintiveRing-tooltip"
                        tooltipText={t(
                            'tooltips:extensions.extToExtCallDisctintiveRing',
                        )}
                    />
                }
            />
            <Separator />
            <SwitchWithLabel
                className={classNames(
                    classes.switchContainer,
                    classes.extraPadding,
                )}
                id="pinProtectionStatus"
                field="pinProtectionStatus"
                label={t('screens:extensions.ivrAuthentication')}
                value={values.pinProtectionStatus}
                disabled={values.pinProtectionBlocked}
                setValue={setFieldValue}
                icon={
                    <IconWithTooltip
                        dataQa="ivr-auth-tooltip"
                        tooltipText={t('tooltips:extensions.ivrAuthentication')}
                    />
                }
            />
            {values.pinProtectionStatus && (
                <Grid item className={classNames(classes.itemsContainer, classes.smallPaddingTop)}>
                    <div className={classes.rowContainer}>
                        <TextField
                            id="serviceUnblockCode"
                            label={t('screens:extensions.serviceUnblockCode')}
                            onChange={(e) => {
                                setFieldValue('serviceUnblockCode', e.target.value);
                                handleChange(e);
                            }}
                            value={values.serviceUnblockCode}
                            type="text"
                            className={classes.pinInput}
                            dataQa="service-unblock-code"
                            required
                            maxLength={15}
                            setFieldError={setFieldError}
                            helperText={errors.serviceUnblockCode}
                            icon={
                                <IconWithTooltip
                                    dataQa="ivr-auth-pin-tooltip"
                                    tooltipText={t('tooltips:extensions.ivrAuthenticationPin')}
                                />
                            }
                            iconPosition="end"
                        />
                    </div>
                </Grid>
            )}
            <Separator />
            <SwitchWithLabel
                className={classNames(
                    classes.switchContainer,
                    classes.extraPadding,
                )}
                id="callWaitingStatus"
                field="callWaitingStatus"
                label={t('screens:extensions.callWaiting')}
                value={values.callWaitingStatus}
                disabled={values.callWaitingBlocked}
                setValue={setFieldValue}
                icon={
                    <IconWithTooltip
                        dataQa="callWaiting-tooltip"
                        tooltipText={t('tooltips:ringGroups.callQueue')}
                    />
                }
            />
        </div>
    );
};

export default CallSettingsForm;
