import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../styles/Colors';
import { EmptyGridPlaceholderProps } from './types';
import { ReactComponent as EmptyMonster } from '../../assets/empty_monster.svg';

const useStyles = makeStyles(() => ({
    noData: {
        color: Colors.LightGraySecondary2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        fontSize: 15,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    message: {
        marginTop: 16,
    },
}));

const EmptyGridPlaceholder: React.VFC<EmptyGridPlaceholderProps> = ({
    message,
    isVisible = false,
    showNoDataImg= true
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (!isVisible) {
        return null;
    }

    return (
        <div className={classes.noData}>
            <div className={classes.container}>
                {showNoDataImg ? <EmptyMonster /> : null}
                <span className={classes.message}>
                    {message || t('common:noData')}
                </span>
            </div>
        </div>
    );
};

export default EmptyGridPlaceholder;
