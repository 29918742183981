import { CallQueue } from './CallQueue';

export interface RingGroupType {
    id: string;
    name: string;
    hunt_sequence: string;
    assigned_callqueue?: CallQueue;
    assigned_extensions?: AssignedExtension[];
    hunt_keep_original_cli?: string;
    i_c_ext?: number;
    i_c_group: number;
    i_customer?: number;
    i_prompt?: number;
    i_ringback_tone?: number;
    pickup_allowed?: string;
    prompt_error?: string;
    prompt_status?: string;
    published?: string;
}

export interface AssignedExtension {
    account_id?: string;
    hunt_active?: string;
    hunt_delay?: number | string | null;
    hunt_expire?: number | string | null;
    hunt_order?: number;
    huntstop?: string;
    i_c_ext?: number;
    i_c_group?: number;
    i_cg_ext?: number;
    i_customer_of_account?: number;
    id?: string;
    name?: string;
    primary_group?: string;
    type?: string;
    supervision_allowed?: 'Y' | 'N';
    paging_allowed?: 'Y' | 'N';
}

export enum CallerID {
    KeepOriginal = 'Y',
    SetToNameAndCLIOfTheHuntGroup = 'N',
    ReplaceCallerInfo = 'I',
}

export enum RingStrategy {
    Order = 'Order',
    Random = 'Random',
    Simultaneous = 'Simultaneous',
    LeastUsed = 'LeastUsed',
}

export type SelectItem = {
    name: string;
    value: number;
    apiValue: string;
};

export interface Interval {
    wholeDay: boolean;
    startTime: string;
    endTime: string;
    daysOfMonth: string;
    days: SelectItem[];
    months: SelectItem[];
    years?: SelectItem[];
}

export enum RingScheduleStatus {
    Always = '0',
    OnlyFollowingTimeInterval = '1',
}
