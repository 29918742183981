import { Colors } from "../../../../styles/Colors";
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import i18n from "../../../../services/i18n";
import { CPRuleInfo } from "../../../../store/types/CallScreening";

export type NewRuleDialogProps = {
    isOpen: boolean;
    order_number: number;
    i_cp_policy: number;
    editObject?: CPRuleInfo;
    clickOnClose?: (changesApplied: boolean) => void;
};

const requiredError = i18n.t<string>('errors:common.emptyInput');
export const newRuleValidationSchema = Yup.object().shape({
    enabled: Yup.string()
        .required(requiredError),
    from_number_i_cp_condition: Yup.number()
        .optional(),
    to_number_i_cp_condition: Yup.number()
        .optional(),
    time_window_i_cp_condition: Yup.number()
        .optional(),
    operation_mode: Yup.number()
        .optional(),
    action: Yup.number()
        .optional(),
    i_response_message: Yup.number()
        .optional(),
});

export const useStyles = makeStyles(() => ({
    modalContainer: {
        '& .MuiDialogContent-root': {
            background: Colors.SmokeBackground,
            height: 576,
            width: 576,
            padding: 0,
            margin: 0,
        },

        '& .MuiDialog-paperWidthSm': {
            maxWidth: 'unset',
            maxHeight: 'unset'
        },

        '& .MuiGrid-root:first-child': {
            marginTop: 0,
        },

        '& .MuiPaper-root': {
            width: 576,
        },
        
        '& .MuiFormHelperText-root': {
            right: 0,
            fontSize: '0.75rem',
            fontWeight: 400
        },
    },

    header: {
        '& .MuiTypography-h6': {
            fontWeight: 700,
            fontSize: 18,
        },
    },

    itemsContainer: {
        paddingLeft: 56,
        paddingTop: 34,
        paddingRight: 80,
        paddingBottom: 18,
        display: 'flex',
        flexDirection: 'column',

        '& p': {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '20px',
        },

        '& .MuiFormControl-root': {
            width: 440,
            minHeight: 56,
            height: 'fit-content'
        },
    },

    form: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        padding: 0,
        margin: 0,
        overflowX: 'hidden'
    },
    
    boxRow: {
        display: 'flex',
        alignItems: 'flex-start',
        width: 440,
        paddingBottom: 24,

        '& .Mui-error': {
            fontWeight: 400,
        },
    },

    switchContainer: {
        marginBottom: 0,
        height: 'fit-content',

        '& .MuiBox-root': {
            '& .MuiBox-root': {
                '& span': {
                    fontWeight: '400 !important',
                }
            }
        }
    },

    error: {
        color: Colors.Error,
        fontSize: 12,
        fontWeight: 400,

        '& svg': {
            display: 'none',
        },
    },

    multiSelectField: {
        minHeight: 56,

        '& .MuiAutocomplete-inputRoot': {
            height: 'fit-content !important',
            minHeight: 56,
        }
    },

    modeChip: {
        '& .MuiChip-label.MuiChip-labelSmall': {
            maxWidth: 326,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    }
}));