import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    UploadDeviceFileProps,
    useStyles,
    initialValues,
    getReadableSize,
} from './UploadDeviceFileDialog.utils';
import DialogContainer, { DialogButton } from '../../components/AlertDialog/DialogContainer';
import { UAListFileFormat, UploadUAListRequest } from '../../store/types/Devices';
import {useDropzone} from 'react-dropzone';
import Button from '../../components/Button/Button';
import { ReactComponent as CloudUpload } from '../../assets/CloudUpload.svg';
import Loader from '../../components/Loader/Loader';
import { actions } from '../../store';
import { APIErrorInterface } from '../../store/types';
import classNames from 'classnames';
import toast from 'react-hot-toast';
import { ReactComponent as UploadOk } from '../../assets/upload_ok.svg';
import { ReactComponent as UploadError } from '../../assets/upload_error.svg';
import { ReactComponent as UploadWarning } from '../../assets/upload_warning.svg';

const UploadDeviceFileDialog: React.FC<UploadDeviceFileProps> = ({
    isModalOpen,
    setIsModalOpen
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [values, setValues] = useState<UploadUAListRequest>(initialValues);
    const [apiErrorString, setApiErrorString] = useState<string>();
    const [uuidString, setUuidString] = useState<string>();
    const [uploadStep, setUploadStep] = useState<number>(1);
    const [errorFileDownloadError, setErrorFileDownloadError] = useState<string>();

    const classes = useStyles();

    const onClose = () => {
        setIsModalOpen(false);
        setTimeout(() => {
            setValues(initialValues);
            setApiErrorString('');
            setUploadStep(1);
            setUuidString('');
        }, 500);
    };

    useEffect(() => {
        if(errorFileDownloadError && (errorFileDownloadError?.length || 0) > 0) {
            toast(errorFileDownloadError || '');
            setErrorFileDownloadError('');
        }
    }, [errorFileDownloadError]);

    const cancelButton = (
        <DialogButton
            key="cancel"
            label={uploadStep >= 4 ? t('common:close') : t('common:cancel')}
            onClick={onClose}
            disabled={uploadStep === 3}
        />
    );

    const saveButton = useMemo(() => (
        <DialogButton
            key="save"
            dataQa="upload-device-save-button"
            onClick={() => {
                setUploadStep(3);
                dispatch(actions.uploadDeviceListCsvFile.request(values));
            }}
            label={t('common:upload')}
            disabled={uploadStep === 1}
            primary
        />
    ), [values, uploadStep]);

    const actionButtons = useMemo(() => uploadStep >= 3 ? [cancelButton] : [
        cancelButton,
        saveButton
    ], [saveButton, uploadStep]);
    
    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles && acceptedFiles.length ? acceptedFiles[0] : undefined;
        if(file) {
            setValues(prevValues => {
                return  ({
                    ...prevValues,
                    file: file,
                    file_format: UAListFileFormat.CSV,
                    name: file.name,
                    fileSizeReadable: getReadableSize(file.size),
                    callback: () => {
                        setUploadStep(4);
                    },
                    errorCallback: (error: APIErrorInterface) => {
                        //@ts-ignore
                        setUuidString(error.faultdetails?.uuid);
                        setApiErrorString(error.faultstring);
                        if(error.faultcode === 'Server.UA.upload_ua_list.partially_succeeded') {
                            setUploadStep(5);
                        } else {
                            setUploadStep(6);
                        }
                    }
                });
            });
            setUploadStep(2);
        }
    }, [])
    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: {'text/csv': ['.csv']}
    });

    return (
        <DialogContainer
            isOpen={isModalOpen}
            header={t('screens:devices.importDevicesFromFileDialogTitle1')}
            headerClass={classes.header}
            dialogActionsButtons={actionButtons}
            className={classes.root}
            dataTestId='modal-container'
        >
            <Grid
                item
                className={classNames(classes.itemsContainer,
                        (uploadStep <= 3) && classes.itemsContainerStep123,
                        (uploadStep >= 4) && classes.itemsContainerStep456
                    )}
            >
                {(uploadStep === 1 || uploadStep === 2) && (
                <div className={classes.fileDragAndDropBox} {...getRootProps()} data-testid="file-drop-zone">
                    <div className={classes.uploadBox}>
                        <div>
                            <CloudUpload className={classes.uploadIcon} />
                        </div>
                        <div>
                            <div className={classes.importTop}>
                                {uploadStep === 1
                                    ? t('screens:devices.importDevicesSelectFile')
                                    : values.name
                                }
                            </div>
                            <div className={classes.importBottom}>
                                {uploadStep === 1
                                    ? t('screens:devices.importDevicesSelectFileSubTitle')
                                    : values.fileSizeReadable
                                }
                            </div>
                        </div>
                        <div className={classes.lastColumn}>
                            <Button
                                dataQa="browse-button"
                                primary
                                accent
                                className={classes.browseButton}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                {t('screens:devices.browse')}
                            </Button>
                        </div>
                    </div>
                    <input {...getInputProps()} data-testid='file-upload-input'/>
                </div>)}
                {uploadStep === 3 && (
                    <div className={classes.loaderContainer}>
                        <Loader dataQa='loader-upload' absolutePosition={false} />
                        <span>{t('screens:devices.uploading')}</span>
                    </div>
                )}
                {(uploadStep === 4 || uploadStep === 5 || uploadStep === 6) && (
                    <div className={classes.resultsContainer}>
                        {uploadStep === 4 && (<>
                            <UploadOk className={classes.resultsIcon} />
                            <div className={classes.uploadDoneText}>{t('screens:devices.uploadCompleted')}</div>
                            </>
                        )}
                        {uploadStep === 5 && (<>
                            <UploadWarning className={classes.resultsIcon} />
                            <div className={classes.uploadDoneText}>{t('screens:devices.uploadPartially1')}</div>
                            <div className={classes.uploadDoneText}>{t('screens:devices.uploadPartially2')}</div>
                            <div className={classes.uploadDoneTextLink}><a href="#" onClick={(e) => {
                                e.preventDefault();
                                dispatch(actions.getUploadUAApiErrorFile.request({
                                    uuid: uuidString || '',
                                    fileName: t('screens:devices.failedDataFileName'),
                                    errorCallback: (fileError: APIErrorInterface) => {
                                        if(fileError?.faultstring) {
                                            setErrorFileDownloadError(fileError.faultstring);
                                        }
                                    }
                                }));
                            }} >{t('screens:devices.failedDataFileName')}</a></div>
                            </>
                        )}
                        {uploadStep === 6 && (<>
                            <UploadError className={classes.resultsIcon} />
                            <div className={classes.uploadDoneText}>{apiErrorString}</div>
                            </>
                        )}
                    </div>
                )}
            </Grid>
        </DialogContainer>
    );
};

export default UploadDeviceFileDialog;
