import { Colors } from '../../../styles/Colors';
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import { RingGroupType } from '../../../store/types/RingGroup';

export enum DispatchType {
    PermamentlyEnabled = 0,
    Custom = 1,
    Disabled = 2,
}

export type AddtoRingGroupFormProps = {
    ignoreFollowMeVoiceMail: boolean;
    setAsPrimary: boolean;
    groupId?: string;
    dispatchType: DispatchType;
    customWaitsFor?: number | string | null;
    customThenRingsFor?: number | string | null;
    supervisor: boolean;
    ringing: boolean;
    allowGroupPaging: boolean;
};

export const formInitialValues: AddtoRingGroupFormProps = {
    ignoreFollowMeVoiceMail: false,
    setAsPrimary: false,
    groupId: undefined,
    dispatchType: DispatchType.PermamentlyEnabled,
    customWaitsFor: 15,
    customThenRingsFor: 30,
    supervisor: false,
    ringing: true,
    allowGroupPaging: false
};

export const addtoRingGroupFormValidationSchema = Yup.object().shape({
    ignoreFollowMeVoiceMail: Yup.bool().required(),
    setAsPrimary: Yup.bool().required(),
    groupId: Yup.string().required(),
    dispatchType: Yup.number().required(),
    customWaitsFor: Yup.number().notRequired(),
    customThenRingsFor: Yup.number().notRequired(),
});

export interface AddToRingGroupDialogProps {
    isOpen: boolean;
    ringGroups?: RingGroupType[];
    extensionRingGroups?: RingGroupType[];
    ringGroupToEdit?: RingGroupType;
    accountId?: string;
    extensionName?: string;
    onSave?: () => void;
    toggleVisibility?: () => void;
    onExclude?: (id: string) => void;
    onAddNewRingGroup?: (form: AddtoRingGroupFormProps) => void;
}

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        backgroundColor: Colors.SmokeBackground,
        display: 'flex',
        flexDirection: 'column',

        '& .MuiFormControl-root': {
            flex: 1,
        },

        '& > :first-child': {
            marginTop: 0,
        },

        '& input[type="number"]::-webkit-inner-spin-button': {
            opacity: 1,
        },
        '& input[type="number"]::-webkit-outside-spin-button': {
            opacity: 1,
        },
    },
    inputs: {
        '& .MuiFormControl-root:first-of-type': {
            marginRight: 90,
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 'bold',
            fontSize: 18,
        },
    },
    modalContainer: {
        '& .MuiDialogContent-root': {
            padding: '34px 82px 36px 82px',
            width: 476,
            height: 600,
            backgroundColor: Colors.SmokeBackground,
        },
    },
    modalContainerForEdit: {
        '& .MuiDialogContent-root': {
            height: 600,
        },
    },
    numberInput: {
        flex: 1,

        '& .Mui-disabled': {
            opacity: 0.5,
        },
    },
    numberLabel: {
        marginLeft: -80,
        marginTop: 18,
    },
    middleOption: {
        margin: '0 50px',
    },
    groupSelect: {
        marginBottom: 16,
    },
    activeHeader: {
        fontWeight: 'bold',
        color: Colors.Gray5,
        marginBlockEnd: '5px',
    },
    inputMargin: {
        marginTop: '20px !important',
    },
    radioContainer: {
        background: Colors.White,
        border: `1px solid ${Colors.Border}`,
        margin: '0 16px 0 0',
        height: 195,
        borderRadius: 4,
        padding: '18px 25px 18px 25px',
        '&:last-of-type': {
            marginRight: 0,
        },
    },
    radioDescription: {
        fontSize: 14,
    },
    selectedRadio: {
        background: Colors.Secondary10,
        border: `2px solid ${Colors.Secondary1}`,
    },
    column: {
        flexDirection: 'column',
    },
    marginBottom: {
        marginBottom: 34,
    },
    ringing: {
        marginLeft: -8,
        marginBottom: 0,
    },
    descriptionRadio: {
        width: 182,
    },
    minText: {
        position: 'absolute',
        marginTop: 16,
        marginLeft: 50,
        top: 10.5
    },
    saveButtonDisabled: {
        backgroundColor: `${Colors.Gray8}!important`,
        '& .MuiButton-label': {
            color: `${Colors.Gray10}!important`,
        },
    },
    confirmDialogContainer: {
        '& .MuiDialogContent-root': {
            minWidth: 'unset',
        },
        '& .MuiPaper-root': {
            width: 336,
        },
    },
}));
