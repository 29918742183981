import {put, takeLatest, call} from 'redux-saga/effects';

//Actions
import * as actions from '../../actions';

//Services
import {
    postChangePassword,
    postLogin,
    postPasswordRecovery
} from '../../services/auth-api';

//Types
import * as Actions from '../../actions/actions';
import * as Payloads from '../../actions/payloads';
import {AxiosResponse} from 'axios';
import {ActionType} from 'typesafe-actions';
import {getSessionData} from '../generic/saga';
import {getCustomerInfoByLogin} from '../myProfile/saga';

export const PASSWORD_EXPIRED_ERROR =
    'Server.Session.alert_You_must_change_password';

export function* signIn(action: ActionType<typeof actions.logIn.request>) {
    const {login, password} = action.payload;
    try {
        localStorage.setItem('user_login', login);

        const res: AxiosResponse<{
            session_id: string;
            access_token: string;
            csrf_token: string;
        }> = yield postLogin({
            login: login.trim(),
            password: password.trim(),
            enable_csrf_protection: 1
        });
        
        localStorage.setItem('session_id', res.data.session_id);
        localStorage.setItem('access_token', res.data.access_token);
        localStorage.setItem('csrf_token', res.data.csrf_token);
        localStorage.removeItem('default_portal_view_configuration');
        yield put(
            actions.logIn.success({
                session_id: res.data.session_id,
                access_token: res.data.access_token,
                csrf_token: res.data.csrf_token
            }),
        );

        yield call(getSessionData);
        yield call(getCustomerInfoByLogin);
    } catch (err) {
        // @ts-ignore
        if (err.response) {
            // @ts-ignore
            if (err.response.data.faultcode == PASSWORD_EXPIRED_ERROR) {
                yield put(
                    actions.logInPasswordExpired({
                        login: login,
                        password: password,
                    }),
                );
            } else {
                // @ts-ignore
                yield put(actions.logIn.failure(err));
            }
        }
    }
}

export function* changePassword(
    action: Actions.PromiseAction<Payloads.LoginPayload &
        Payloads.PasswordPayload &
        Payloads.ChangePasswordPayload>,
) {
    const {login, password, new_password} = action.payload;

    try {
        const res: AxiosResponse<{
            success: number;
        }> = yield postChangePassword({
            login: login,
            password: password,
            new_password: new_password,
        });
        yield put(actions.passwordChange.success(res.data));
    } catch (err) {
        // @ts-ignore
        yield put(actions.passwordChange.failure(err));
    }
}

export function* passwordRecovery(
    action: ActionType<typeof actions.passwordRecovery.request>,
) {
    try {
        const res: AxiosResponse<{
            success: number;
        }> = yield postPasswordRecovery(action.payload);
        yield put(actions.passwordRecovery.success(res.data));
    } catch (err) {
        // @ts-ignore
        yield put(actions.passwordRecovery.failure(err));
    }
}

export function* emailMePasswordRecovery(
    action: Actions.PromiseAction<Payloads.MailMePayload>,
) {
    try {
        const res: AxiosResponse<{
            success: number;
        }> = yield postPasswordRecovery(action.payload);

        if (res.data.success) {
            action.payload.callback?.();
        }

        yield put(actions.sendRecoveryPasswordEmail.success(res.data));
    } catch (err) {
        // @ts-ignore
        yield put(actions.sendRecoveryPasswordEmail.failure(err));
    }
}

export function* signInFromAdmin(action: ActionType<typeof actions.signInFromAdmin.request>) {
    try {
        const {value} = action.payload;
        const data = JSON.parse(Buffer.from(value, "base64").toString());
        const session_id = data.pb_session_id;
        const token = data.access_token;
        
        yield put(actions.removeAuthDataAndReload({reload: false}));

        localStorage.setItem('session_id', session_id);
        localStorage.setItem('access_token', token);
        localStorage.setItem('csrf_token', data.csrf_token);
        localStorage.setItem('default_portal_view_configuration', data.i_portal_view_configuration);
        yield put(
            actions.logIn.success({
                session_id: session_id,
                access_token: token,
                csrf_token: data.csrf_token
            }),
        );

        yield call(getSessionData);
        yield call(getCustomerInfoByLogin);
    } catch (err) {
        // @ts-ignore
        yield put(actions.logIn.failure(err));
    }
}

export function* removeAuthDataAndReload (action: ActionType<typeof actions.removeAuthDataAndReload>) {

    localStorage.removeItem('session_id');
    localStorage.removeItem('user_info');
    localStorage.removeItem('user_email');
    localStorage.removeItem('csrf_token');
    localStorage.removeItem('default_portal_view_configuration');
    localStorage.removeItem('porta_one_menu');
    localStorage.removeItem('user_login');

    if(action.payload?.reload){
        window.location.reload();
    }
}

export const authSaga = [
    takeLatest(actions.logIn.request, signIn),
    takeLatest(
        actions.sendRecoveryPasswordEmail.request,
        emailMePasswordRecovery,
    ),
    takeLatest(actions.passwordChange.request, changePassword),
    takeLatest(actions.passwordRecovery.request, passwordRecovery),
    takeLatest(actions.signInFromAdmin.request, signInFromAdmin),
    takeLatest(actions.removeAuthDataAndReload, removeAuthDataAndReload),
];