import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {Box, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import PermissionProvider from '../../../PermissionProvider/PermissionProvider';
import DialogContainer, { DialogButton } from '../../../AlertDialog/DialogContainer';
import { Permission } from '../../../../store/types/Permission';
import Loader from '../../../Loader/Loader';
import { NewRuleDialogProps, newRuleValidationSchema, useStyles } from './NewRuleDialog.utils';
import { CPConditionInfo, CPRuleInfo, CPRuleInfoAction, YesNo } from '../../../../store/types/CallScreening';
import SwitchWithLabel from '../../../SwitchWithLabel/SwitchWithLabel';
import SelectField from '../../../SelectField/SelectField';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';
import CustomizedChip from '../../../Chip/Chip';
import { CallScreeningMode, toBitMask, translateBitMak } from '../../../../utils/extensions/translateBitMak';
import { actions } from '../../../../store';
import { ReduxState } from '../../../../store/types';
import { GetCallProcessingPolicyListEntity } from '../../../../store/actions/extensions/payloads';
import { TimeFiltersPageFilterType } from './NewTimeFilterDialog.utils';

const NewRuleDialog: React.FC<NewRuleDialogProps> = ({
        isOpen,
        editObject,
        clickOnClose,
        order_number,
        i_cp_policy
    }) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const isLoading: boolean = useSelector((state: ReduxState) => 
        state.extensions.isEditingPolicy || false);
    
    const { cpConditionsList } = useSelector(
        (state: ReduxState) => state.callSettings,
    );
    
    const modesList = useSelector(
        (state: ReduxState) => state.extensions?.callProcessingOperationModeList || [],
    );

    const cpConditionDefaultValue: CPConditionInfo = {
        name: t('screens:callSettings.anyNumber'),
        i_cp_condition: undefined
    };
        
    const fromList = useMemo(() => {
        const filtered =
            cpConditionsList?.cp_condition_list?.filter((v) => v.type === TimeFiltersPageFilterType.FromNumber.toString()) || [];
        filtered.splice(0, 0, cpConditionDefaultValue);
        return filtered;
    }, [cpConditionsList, cpConditionDefaultValue]);
    
    const toList = useMemo(() => {
        const filtered =
            cpConditionsList?.cp_condition_list?.filter((v) => v.type === TimeFiltersPageFilterType.ToNumber.toString()) || [];
        filtered.splice(0, 0, cpConditionDefaultValue);
        return filtered;
    }, [cpConditionsList, cpConditionDefaultValue]);
    
    const periodDefaultValue: CPConditionInfo = {
        name: t('common:always'),
        i_cp_condition: undefined
    };
    
    const periodList = useMemo(() => {
        const filtered =
            cpConditionsList?.cp_condition_list?.filter((v) => v.type === TimeFiltersPageFilterType.TimeWindow.toString()) || [];
        filtered.splice(0, 0, periodDefaultValue);
        return filtered;
    }, [cpConditionsList, cpConditionDefaultValue]);
    
    const modeDefaultValue = t('screens:extensions.any');

    const modes = useMemo(() => {
        const filtered = modesList
            .map(e => e.name)
            .map((item) =>
                item == CallScreeningMode.Deafault
                    ? t<string>('enums:callScreeningMode.Default')
                    : item,
            );
        filtered.splice(0, 0, modeDefaultValue);
        return filtered;
    }, [modesList, modeDefaultValue]);

    const actionsList = Object.values(CPRuleInfoAction)
        .filter((v) => Number.isInteger(v))
        .map((v) => ({
            name: t(`enums:callScreeningActionEnum.${v}`),
            value: v,
        }));
        
    const defaultAction = useMemo(() => {
        return actionsList.find(e => e.value === CPRuleInfoAction.RingForwardVoicemail);
    },
    [actionsList]);
    
    const files = useSelector(
        (state: ReduxState) => state.callSettings?.customerResponseMessages?.file_list || [],
    );

    const fileDefaultValue = {
        name: t('common:notSet'),
        value: undefined as number | undefined
    };
    
    const filesList = useMemo(() => {
        const filtered = files.map(e => ({
            name: e.name,
            value: e.id as number | undefined
        }));
        filtered.splice(0, 0, fileDefaultValue);
        return filtered;
    }, [files, fileDefaultValue]);
        
    const policies = useSelector<ReduxState, GetCallProcessingPolicyListEntity[] | undefined>((state) => 
        state.extensions.callProcessingPolicyList);

    const initialValues: CPRuleInfo = useMemo(
        () => ({
            enabled: editObject?.enabled || 'Y',
            from_number_i_cp_condition: editObject?.from_number_i_cp_condition || undefined,
            to_number_i_cp_condition: editObject?.to_number_i_cp_condition || undefined, 
            time_window_i_cp_condition: editObject?.time_window_i_cp_condition || undefined,
            operation_mode: editObject?.operation_mode || toBitMask([modeDefaultValue], modesList),
            action: editObject?.action || defaultAction?.value,
            i_response_message: editObject?.i_response_message || fileDefaultValue?.value,
            is_active: editObject?.is_active || 'Y',
            order_number: editObject?.order_number || order_number,
            i_cp_policy: editObject?.i_cp_policy || i_cp_policy,
            i_cpp_rule: editObject?.i_cpp_rule || undefined
        } as CPRuleInfo),
        [editObject, modesList, modeDefaultValue, defaultAction, fileDefaultValue, order_number, i_cp_policy],
    );

    const {
        values,
        handleSubmit,
        resetForm,
        setFieldError,
        setFieldValue,
        errors,
        dirty
    } = useFormik<CPRuleInfo>({
        initialValues,
        onSubmit: (form) => {

            const patchedForm = {
                ...form,
                to_number_i_cp_condition: form.to_number_i_cp_condition || null,
                from_number_i_cp_condition: form.from_number_i_cp_condition || null,
                time_window_i_cp_condition: form.time_window_i_cp_condition || null,
                i_response_message: form.i_response_message || null
            };

            const payload = policies?.find(e => e.i_cp_policy === i_cp_policy);
            if(!payload) {
                return;
            }

            if(!payload.rule_list) {
                payload.rule_list = [];
            }

            const extRule = (patchedForm.i_cpp_rule || 0) > 0 ? payload.rule_list.find(e => e.i_cpp_rule === patchedForm.i_cpp_rule) : undefined;
            if(extRule) {
                const indx = payload.rule_list.indexOf(extRule);
                payload.rule_list[indx] = {
                    ...extRule,
                    ...patchedForm
                };
            } else {
                payload.rule_list.push(patchedForm);
            }
            dispatch(actions.editPolicy.request({
                object: payload,
                successToastText: editObject
                    ? t('screens:callSettings.editRuleSuccess')
                    : t('screens:callSettings.addRuleSuccess'),
                callback: () => {
                    clickOnClose?.(true);
                    resetForm();
                    dispatch(actions.getCallProcessingPolicyList.request({
                        with_rules: 1,
                        i_customer: payload.i_customer
                    }));
                }
            }));
        },
        validationSchema: newRuleValidationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true
    });
    
    const selectedModes =  useMemo(
        () => {
            if(values.operation_mode) {
                return translateBitMak(values.operation_mode, modesList || [])?.modes;
            }
            return [modeDefaultValue];
        },
    [values.operation_mode, modes, modesList]);

    const renderModes = useCallback(
        (selected: string[]) => {
            return selected.map((v) => (
                <CustomizedChip
                    key={v}
                    label={v}
                    customClasses={{chip: classes.modeChip}}
                    handleDelete={
                        () => {
                            if(v !== modeDefaultValue) {
                                const choosen = selectedModes.filter(e => e !== v);
                                const decValue = toBitMask(choosen, modesList);
                                setFieldValue('operation_mode', decValue);
                            }
                        }
                    }
                />
            ));
        },
        [modesList, selectedModes],
    );

    return (
        <PermissionProvider permission={Permission.Calls.Settings.CallScreening.value}>
            <DialogContainer
                className={classes.modalContainer}
                isOpen={isOpen}
                dataQa={'rules-add-new-modal'}
                header={editObject
                    ? t('screens:callSettings.editRuleDialogTitle')
                    : t('screens:callSettings.addNewRuleDialogTitle')
                }
                headerClass={classes.header}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => {
                            resetForm();
                            clickOnClose?.(false);
                        }}
                    />,
                    <DialogButton
                        key="save"
                        label={t('common:save')}
                        disabled={
                            (editObject && !dirty) ||
                            isLoading
                        }
                        onClick={handleSubmit}
                        dataQa={'add-new-rule-save-button'}
                        dataTestId={'add-new-rule-save-button'}
                        primary
                    />,
                ]}
            >
                <form
                    className={classes.form}
                    autoComplete="off"
                    data-testid="add-new-rule-form"
                >
                    <Grid className={classes.itemsContainer}>
                        <Box className={classes.boxRow}>
                            <SwitchWithLabel
                                className={classes.switchContainer}
                                label={t('screens:callSettings.enableRule')}
                                setValue={(f, v) => {
                                    setFieldValue(f, v ? YesNo.Yes : YesNo.No);
                                }}
                                value={values.enabled === YesNo.Yes}
                                field={'enabled'}
                                id={'enabled'}
                                dataQa={'enable-rule-switch'}
                            />
                        </Box>

                        <Box className={classes.boxRow}>
                            <SelectField
                                id="from_number_i_cp_condition"
                                label={t(
                                    'common:from',
                                )}
                                getOptionLabel={(value: CPConditionInfo) => value?.name || ''}
                                onChange={(_, option: CPConditionInfo) => {
                                    if(option) {
                                        setFieldValue('from_number_i_cp_condition', option.i_cp_condition);
                                    }
                                }}
                                items={fromList}
                                defaultValue={cpConditionDefaultValue}
                                value={fromList.find(e => e.i_cp_condition === values.from_number_i_cp_condition)}
                                helperText={
                                    errors.from_number_i_cp_condition && errors.from_number_i_cp_condition.length > 0
                                        ? errors.from_number_i_cp_condition
                                        : ''
                                }
                                classes={{helperText: classes.error}}
                                setFieldError={setFieldError}
                                disableClearable
                                required
                                dataQa={'model-select-from'}
                                dataTestId={'model-select-from'}
                            />
                        </Box>

                        <Box className={classes.boxRow}>
                            <SelectField
                                id="to_number_i_cp_condition"
                                label={t(
                                    'common:to',
                                )}
                                getOptionLabel={(value: CPConditionInfo) => value?.name || ''}
                                onChange={(_, option: CPConditionInfo) => {
                                    if(option) {
                                        setFieldValue('to_number_i_cp_condition', option.i_cp_condition);
                                    }
                                }}
                                items={toList}
                                defaultValue={cpConditionDefaultValue}
                                value={toList.find(e => e.i_cp_condition === values.to_number_i_cp_condition)}
                                helperText={
                                    errors.to_number_i_cp_condition && errors.to_number_i_cp_condition.length > 0
                                        ? errors.to_number_i_cp_condition
                                        : ''
                                }
                                classes={{helperText: classes.error}}
                                setFieldError={setFieldError}
                                disableClearable
                                required
                                dataQa={'model-select-to'}
                                dataTestId={'model-select-to'}
                            />
                        </Box>

                        <Box className={classes.boxRow}>
                            <SelectField
                                id="time_window_i_cp_condition"
                                label={t(
                                    'common:period',
                                )}
                                getOptionLabel={(value: CPConditionInfo) => value?.name || ''}
                                onChange={(_, option: CPConditionInfo) => {
                                    if(option) {
                                        setFieldValue('time_window_i_cp_condition', option.i_cp_condition);
                                    }
                                }}
                                items={periodList}
                                defaultValue={periodDefaultValue}
                                value={periodList.find(e => e.i_cp_condition === values.time_window_i_cp_condition)}
                                helperText={
                                    errors.time_window_i_cp_condition && errors.time_window_i_cp_condition.length > 0
                                        ? errors.time_window_i_cp_condition
                                        : ''
                                }
                                classes={{helperText: classes.error}}
                                setFieldError={setFieldError}
                                disableClearable
                                required
                                dataQa={'model-select-period'}
                                dataTestId={'model-select-period'}
                            />
                        </Box>
                        
                        <Box className={classes.boxRow}>
                            <SelectField
                                label={t('screens:callSettings.mode')}
                                className={classes.multiSelectField}
                                onChange={(e, value: string[]) => {
                                    if(value && value.length > 1 && 
                                        value[value.length - 1] === modeDefaultValue)
                                    {
                                        //only the case when Any selected as last - should dechoose all others
                                        const decValue = toBitMask([modeDefaultValue], modesList);
                                        setFieldValue('operation_mode', decValue);
                                    } else {
                                        const decValue = toBitMask(value, modesList);
                                        setFieldValue('operation_mode', decValue);
                                    }
                                }}
                                items={modes}
                                value={selectedModes}
                                multiple
                                icon={
                                    <IconWithTooltip
                                        dataQa="modes-tooltip"
                                        tooltipText={t('tooltips:callSettings.modeSelect')}
                                    />
                                }
                                dataQa="modes-input"
                                renderTags={renderModes}
                                disableClearable
                                disableAutoSettingValue
                                id='operation_mode'
                            />
                        </Box>
                        
                        <Box className={classes.boxRow}>
                            <SelectField
                                id="action"
                                label={t('screens:callSettings.action')}
                                getOptionLabel={(value: {name: string, value: string}) => value?.name || ''}
                                onChange={(_, option: {name: string, value: string}) => {
                                    if(option) {
                                        setFieldValue('action', option.value);
                                    }
                                }}
                                items={actionsList}
                                defaultValue={defaultAction}
                                value={actionsList.find(e => e.value === values.action)}
                                helperText={
                                    errors.action && errors.action.length > 0
                                        ? errors.action
                                        : ''
                                }
                                classes={{helperText: classes.error}}
                                setFieldError={setFieldError}
                                disableClearable
                                required
                                dataQa={'model-select-action'}
                                dataTestId={'model-select-action'}
                            />
                        </Box>
                        
                        <Box className={classes.boxRow}>
                            <SelectField
                                id="i_response_message"
                                label={t('screens:callSettings.responseMessage')}
                                getOptionLabel={(value: {name: string, value: string}) => value?.name || ''}
                                onChange={(_, option: {name: string, value: string}) => {
                                    if(option) {
                                        setFieldValue('i_response_message', option.value);
                                    }
                                }}
                                items={filesList}
                                defaultValue={fileDefaultValue}
                                value={filesList.find(e => e.value === values.i_response_message)}
                                helperText={
                                    errors.i_response_message && errors.i_response_message.length > 0
                                        ? errors.i_response_message
                                        : ''
                                }
                                classes={{helperText: classes.error}}
                                setFieldError={setFieldError}
                                disableClearable
                                required
                                dataQa={'model-select-file'}
                                dataTestId={'model-select-file'}
                            />
                        </Box>
                    </Grid>
                </form>
                {isOpen && isLoading && (
                    <Loader dataQa={'wait-for-data'} absolutePosition />
                )}
            </DialogContainer>
        </PermissionProvider>
    );
};

export default NewRuleDialog;