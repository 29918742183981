import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { GetCallProcessingPolicyListEntity, OperationMode } from "../../../../store/actions/extensions/payloads";
import { ReduxState } from "../../../../store/types/store";
import { Colors } from "../../../../styles/Colors";
import DataGrid from "../../../DataGrid/DataGrid";
import { TFunction, useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import classNames from "classnames";
import CustomizedIconButton from "../../../IconButton/IconButton";
import { Delete, Edit } from "@material-ui/icons";
import CreationButton from "../../../CreationButton/CreationButton";
import StickyCell from "./StickyCell";
import { Permission } from "../../../../store/types/Permission";
import NewOperationModeDialog from "./NewOperationModeDialog";
import { NewOperationModeDialogProps } from "./NewOperationModeDialog.utils";
import { translateBitMak } from "../../../../utils/extensions/translateBitMak";

export type PolicyModesTabProps = {
    onDelete: (mode: OperationMode) => void
};

const useStyles = makeStyles(() => ({
    tabsContainer:{
        backgroundColor: Colors.White,
        border: 'none',
        width: 'calc(100% - 64px)',
        paddingLeft: 32,
        paddingRight: 32,

        '& table': {
            width: 760,

            '& .MuiTableBody-root .MuiTableCell-root': {
                height: 57
            },
        }
    },
    defaultValue: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 16,
        color: Colors.Gray6,
        cursor: 'default'
    },
    valueSpan: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 16,
        color: Colors.Text
    },
    stickyIcon: {
        color: Colors.Secondary1,
        border: `1px solid ${Colors.Secondary1}`,
        padding: '3px 8px',
        marginLeft: 16
    },
    addModeButton: {
        marginTop: 16,
        marginLeft: 32,
        marginBottom: 22
    },
    cropLongValues: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }
}));

export const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    policies: GetCallProcessingPolicyListEntity[],
    modes: OperationMode[],
    onEdit?: (object: OperationMode) => void, 
    onDelete?: (object: OperationMode) => void, 
): Column<OperationMode>[] => {

    const isModeInUse = (mode: OperationMode) => {
        if(!policies?.length) return false;
        for(const policy of policies) {
            if(!policy?.rule_list?.length) continue;
            for(const rule of policy.rule_list) {
                const cpId = rule.operation_mode || 0;
                const usedModes = translateBitMak(cpId, modes || [])?.modes;
                if(usedModes.includes(mode.name)) {
                    return true;
                }
            }
        }
        return false;
    };

    return [
        {
            Header: t<string>('common:name'),
            accessor: 'name',
            width: 2,
            Cell: function Cell(params) {
                const name = params.row.original.name;
                const isDefault = (params.row.original.short_code || 0) === 0;
                const isSticky = params.row.original.sticky === 'Y';
                return (
                    <StickyCell
                        text={isDefault ? t('enums:callScreeningMode.Default') : name}
                        stickyText={t('screens:callSettings.sticky')}
                        isSticky={isSticky}
                        customClasses={{
                            valueText: classNames(isDefault ? classes.defaultValue : undefined, classes.cropLongValues),
                            stickyText: classes.stickyIcon
                        }}
                        onClick={() => {
                            !isDefault && onEdit?.(params.row.original);
                        }}
                        permissions={Permission.Calls.Settings.CallScreening.value}
                    />
                );
            },
        },
        {
            Header: t<string>('screens:callSettings.dialToSwitch'),
            accessor: 'short_code',
            width: 1,
            Cell: function Cell(params) {
                const value = params.row.original.short_code || 0;
                const isDefault = (params.row.original.short_code || 0) === 0;
                return (<span className={classNames(isDefault ? classes.defaultValue : classes.valueSpan)}>{value}</span>);
            },
        },
        {
            Header: t<string>('screens:callSettings.expiresAfter'),
            accessor: 'default_timeout',
            width: 1,
            Cell: function Cell(params) {
                const value = params.row.original.default_timeout || 0;
                const isDefault = (params.row.original.short_code || 0) === 0;
                return (
                    <span className={classNames(isDefault ? classes.defaultValue : classes.valueSpan)}>
                        {value === 0 ? '-' : value.toString()}
                    </span>
                );
            },
        },
        {
            Header: '',
            accessor: 'i_operation_mode',
            width: 1,
            Cell: function Cell(params) {
                const original = params.row.original;
                const isDefault = (params.row.original.short_code || 0) === 0;
                return isDefault ? (<></>) : (<>
                    <CustomizedIconButton
                        onClick={() => {
                            onEdit?.(original);
                        }}
                        dataTestId="edit-mode-item-button"
                        dataQa="edit-mode-item-button"
                        tooltipText={t<string>('common:edit')}
                    >
                        <Edit htmlColor={Colors.ListIconColor}/>
                    </CustomizedIconButton>
                    {!isModeInUse(original) && (
                        <CustomizedIconButton
                            onClick={() => {
                                onDelete?.(original);
                            }}
                            dataTestId="remove-mode-item-button"
                            dataQa="remove-mode-item-button"
                            tooltipText={t<string>('common:delete')}
                        >
                            <Delete htmlColor={Colors.ListIconColor}/>
                        </CustomizedIconButton>)
                    }
                </>);
            },
        }
    ];
}

const PolicyModesTab: React.FC<PolicyModesTabProps> = ({
    onDelete
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    const policies = useSelector(
        (state: ReduxState) => state.extensions?.callProcessingPolicyList || [],
    );
    
    const callProcessingOperationModeList = useSelector(
        (state: ReduxState) => state.extensions?.callProcessingOperationModeList || [],
    );
    
    const [showAddEditModeDialog, setShowAddEditModeDialog] = useState<NewOperationModeDialogProps>({
        isOpen: false
    });
    
    const columns = generateColumns(
        t,
        classes,
        policies,
        callProcessingOperationModeList,
        (object: OperationMode) => {
            setShowAddEditModeDialog({
                isOpen: true,
                editObject: object,
                clickOnClose: () => {
                    setShowAddEditModeDialog({
                        isOpen: false,
                        editObject: undefined,
                    });
                }
            });
        },
        onDelete
    );

    return (
        <>
            <DataGrid<OperationMode>
                classes={{
                    tableContainer: classes.tabsContainer,
                }}
                columns={columns}
                data={callProcessingOperationModeList}
                rowCount={callProcessingOperationModeList?.length || 0}
                pageSizeOptions={[callProcessingOperationModeList?.length || 0]}
                initialPageSize={Number.MAX_VALUE}
                centeredRows
                hideFooter
                getItemId={(r) => r.i_operation_mode}
            />
            <CreationButton
                data-qa="add-mode-button"
                onClick={() => {
                    setShowAddEditModeDialog({
                        isOpen: true,
                        editObject: undefined,
                        clickOnClose: () => {
                            setShowAddEditModeDialog({
                                isOpen: false,
                                editObject: undefined,
                            });
                        }
                    });
                }}
                className={classes.addModeButton}
                title={t('screens:callSettings.addMode')}
            />
            <NewOperationModeDialog
                {...showAddEditModeDialog}
            />
        </>
    )
}

export default PolicyModesTab;