import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';

export const useStyles = makeStyles({
    container: {
        height: '100vh',
        overflowX: 'hidden',
        overflowY: 'auto',
        flex: 1,
    },
    tagContainer:{
        position:"fixed",
        bottom:37,
        paddingLeft:20
    },
    scrollableContainer: {
        display: 'flex',
        minHeight: '100vh',
        flex: 1,
        backgroundColor: Colors.White
    },
    containerPadding: {
        background: Colors.White,
        padding: '40px 24px 40px 24px',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 860,
        
        '@media (min-width: 1520px)': {
            padding: '40px 90px 40px 90px',
        },

        '@media (min-width: 1800px)': {
            minWidth: 1000,
        },
    },
    mainHeader: {
        fontSize: 32,
        color: Colors.Gray7,
        fontWeight: 500,
        paddingBottom: 32,
    },
    sectionHeader: {
        fontSize: 18,
        color: Colors.Gray7,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    row: {
        display: 'flex',
        marginBottom: 32,

        '& > *': {
            marginRight: 32,
        },
        '& :last-child': {
            marginRight: 0,
        },
    },
    dot: {
        height: 9,
        width: 9,
        borderRadius: '50%',
        backgroundColor: Colors.Support,
    },
    redDot: {
        backgroundColor: Colors.Error,
    },
    orangeDot: {
        backgroundColor: Colors.Primary,
    },
    emptyCard: {
        flex: 1,
    },
    loaderContainer: {
        height: 148,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    doughnutsContainer: {
        display: 'flex',
        margin: -12,
        marginBottom: 12,
    },
    rightSidePanel: {
        backgroundColor: Colors.LightGray,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: '110px 20px',
        height: '100%',
        position:'absolute',
        right:0,
        width:'18%',

        '@media (min-width: 1600px)': {
            padding: '110px 64px',
        }
    },
    rightSidePanelWithEmptyContext: {
        backgroundColor: Colors.White,
        padding: 0
    },
    rightSidePanelContent: {
    },
    hiddenRightSidePanelContent: {
        display: 'none'
    },
    panelHeader: {
        fontWeight: 500,
        color: Colors.Text,
        fontSize: 20,
        marginLeft: 18,
        marginBottom: 20,
        display: 'flex',
    },
});
