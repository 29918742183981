import { createAsyncAction } from 'typesafe-actions';
import { APIErrorInterface } from '../../types';
import { ApiFile } from '../../types/ApiFile';
import { RingGroupType } from '../../types/RingGroup';
import {
    AddRingGroupRequest,
    ChangeCallQueueStatusRequest,
    ChangeCallQueueStatusResponse,
    EditRingGroupRequest,
    GroupMembersDetailsSuccessPayload,
    RemoveRingGroupPayload,
    RingGroupDetailsRequest,
    RingGroupListRequestPayload,
    RingGroupListResponsePayload,
    UploadRingbackToneRequestPayload,
    CreateRingGroupRequest,
    GetHuntGroupListRequest,
} from './payloads';

export const ringGroupsList = createAsyncAction(
    'GET_RINGGROUPS_LIST',
    'GET_RINGGROUPS_LIST_SUCCESSED',
    'GET_RINGGROUPS_LIST_FAILED',
)<RingGroupListRequestPayload, RingGroupListResponsePayload, Error>();

export const removeRingGroup = createAsyncAction(
    'REMOVE_RINGGROUP',
    'REMOVE_RINGGROUP_SUCCESSED',
    'REMOVE_RINGGROUP_FAILED',
)<RemoveRingGroupPayload, undefined, Error>();

export const ringbackTonesList = createAsyncAction(
    'GET_RINGBACK_TONES_LIST',
    'GET_RINGBACK_TONES_LIST_SUCCESSED',
    'GET_RINGBACK_TONES_LIST_FAILED',
)<undefined, ApiFile[], Error>();

export const onHoldList = createAsyncAction(
    'GET_ON_HOLD_LIST',
    'GET_ON_HOLD_LIST_SUCCESSED',
    'GET_ON_HOLD_LIST_FAILED',
)<undefined, ApiFile[], Error>();

export const onResponseMessageList = createAsyncAction(
    'GET_RESPONSE_MESSAGE_LIST',
    'GET_RESPONSE_MESSAGE_LIST_SUCCESS',
    'GET_RESPONSE_MESSAGE_LIST_SUCCESS',
)<undefined, ApiFile[], undefined>();

export const uploadRingbackTone = createAsyncAction(
    'UPLOAD_RINGBACK_TONE_FILE',
    'UPLOAD_RINGBACK_TONE_FILE_SUCCESSED',
    'UPLOAD_RINGBACK_TONE_FILE_FAILED',
)<UploadRingbackToneRequestPayload, undefined, Error>();

export const prepareDataForAddNewRingGroup = createAsyncAction(
    'PREPARE_DATA_FOR_ADD_NEW_RING_GROUP',
    'PREPARE_DATA_FOR_ADD_NEW_RING_GROUP_SUCCESSED',
    'PREPARE_DATA_FOR_ADD_NEW_RING_GROUP_FAILED',
)<undefined, undefined, Error>();

export const addRingGroup = createAsyncAction(
    'ADD_RING_GROUP',
    'ADD_RING_GROUP_SUCCEEDED',
    'ADD_RING_GROUP_FAILED',
)<AddRingGroupRequest, undefined, APIErrorInterface>();

export const createNewRingGroup = createAsyncAction(
    'CREATE_RING_GROUP',
    'CREATE_RING_GROUP_RING_GROUP_SUCCEEDED',
    'CREATE_RING_GROUP_RING_GROUP_FAILED',
)<CreateRingGroupRequest, undefined, APIErrorInterface>();

export const groupMembersDetails = createAsyncAction(
    'GROUP_MEMBERS_DETAILS',
    'GROUP_MEMBERS_DETAILS_SUCCEEDED',
    'GROUP_MEMBERS_DETAILS_FAILED',
)<undefined, GroupMembersDetailsSuccessPayload, undefined>();

export const ringGroupDetails = createAsyncAction(
    'RING_GROUP_DETAILS',
    'RING_GROUP_DETAILS_SUCCEEDED',
    'RING_GROUP_DETAILS_FAILED',
)<RingGroupDetailsRequest, RingGroupType, APIErrorInterface | undefined>();

export const editRingGroup = createAsyncAction(
    'EDIT_RING_GROUP',
    'EDIT_RING_GROUP_SUCCEEDED',
    'EDIT_RING_GROUP_FAILED',
)<EditRingGroupRequest, undefined, APIErrorInterface>();

export const editRingGroupCallQueueStatus = createAsyncAction(
    'EDIT_RING_GROUP_CALL_QUEUE_STATUS',
    'EDIT_RING_GROUP_CALL_QUEUE_STATUS_SUCCEEDED',
    'EDIT_RING_GROUP_CALL_QUEUE_STATUS_FAILED',
)<
    ChangeCallQueueStatusRequest,
    ChangeCallQueueStatusResponse,
    APIErrorInterface
>();

export const getHuntGroupList = createAsyncAction(
    'GET_HET_HUNT_GROUP_LIST',
    'GET_HET_HUNT_GROUP_LIST_SUCCESS',
    'GET_HET_HUNT_GROUP_LIST_FAILED',
)<GetHuntGroupListRequest, RingGroupType[], undefined>();
