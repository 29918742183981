import CustomizedTextField from "../TextField/TextField";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {UATypeInfo} from "../../store/types/Devices";
import {ClickAwayListener} from "@material-ui/core";

export type AsciiTextFieldProps = {
    value?: string;
    error?: string;
    setFieldError?: (field: string, value: string | undefined) => void;
    onChange?: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
    model?: UATypeInfo;
    id?: string;
    className?: string;
};

export enum Models {
    Yealink = 'Yealink'
}

const AsciiTextField: React.VFC<AsciiTextFieldProps> = (
    {value, error, setFieldError, onChange, model, id = 'ascii_key', className}
) => {

    const [isOutside, setIsOutside] = useState(false);
    const [customError, setCustomError] = useState<string>('');
    const {t} = useTranslation();
    const ref = useRef();

    const maxLengthOfAsciiAesKey: number = useMemo(
        () => {
            if (model
                && (model?.i_ua_type || 0) > 0
                && model.manufacturer
                && model.manufacturer.toLocaleLowerCase() !== Models.Yealink.toLocaleLowerCase()) {

                const asciiLength = value?.length || 0;
                if (asciiLength < 16) {
                    return 16;
                } else if (asciiLength < 32) {
                    return 32;
                }
                return 64;
            }

            return 16;
        },
        [model, value],
    );

    // @ts-ignore
    const buttonRef = ref.current && ref.current.children[0];

    useEffect(() => {
        if (isOutside) {
            if (value && value?.length > 0 && ![16, 32, 64].find((n: number) => n === value?.length)) {
                const errorValue = (): number => {
                    if (value.length < 16) {
                        return 16
                    } else if (value.length < 32) {
                        return 32
                    }else {
                        return 64
                    }
                }
                const msg = t<string>('screens:devices.hintAscii', {value: errorValue()});
                setCustomError(msg);
            }

        } else {
            setCustomError('');
        }
    }, [isOutside]);


    return (
        <ClickAwayListener onClickAway={() => setIsOutside(true)}>
            <div ref={ref} className={className}>
                <CustomizedTextField
                    id={id}
                    label={t('screens:devices.asciiAesKey')}
                    value={value}
                    helperText={
                        error && error.length > 0
                            ? error
                            : '' || customError && customError?.length > 0 ? customError : ''
                    }
                    setFieldError={setFieldError}
                    onChange={onChange}
                    maxLength={maxLengthOfAsciiAesKey}
                    dataQa="ascii-key-field"
                    onFocus={() => setIsOutside(false)}
                />
            </div>

        </ClickAwayListener>
    )

}

export default AsciiTextField;