import {Column} from "react-table";
import {TransactionTableRow} from "./TransactionList.utils";
import {makeStyles} from "@material-ui/core";
import {Colors} from "../../../styles/Colors";

export type TransactionChartProps = {
    columns: Column<TransactionTableRow>[];
    inputdata: TransactionTableRow[];
    isLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: any;
    currency: string;
    dataQa: string;
    hideFooter?:boolean,
    message?:string,
    showNoDataMsg?:boolean
}

export const useStyles = makeStyles(() => ({
    header: {
        '& th:nth-child(1)': {
            display: ' flex',
            justifyContent: 'start',
        },
        '& th:nth-child(2)': {
            display: ' flex',
            justifyContent: 'end',
            width: 55
        },
        '& th:nth-child(3)': {
            display: ' flex',
            justifyContent: 'end',
            width: '138px',
            paddingLeft: '10px',
            paddingRight: 0,
            flex: 'unset',
            boxSizing: 'unset'
        },
        '& th:nth-child(4)': {
            display: ' flex',
            justifyContent: 'center',
            paddingRight: '11px !important',
            flex: '15 0 auto',
            padding: 0
        },
        '& tr:last-child': {
            borderBottom: '1px solid ' + Colors.Border
        },
        '& .MuiTableCell-head': {
            paddingRight: 0
        }
    },

    tableContent: {
        marginLeft: 32,
        width: 'calc(100% - 264px)',
        minWidth: 450,
        overflowX: 'hidden',
        border: 'none',
        '& td': {
            alignItems: 'center !important',
        },

        '& td:nth-child(1)': {
            display: 'flex',
            justifyContent: 'start',
        },
        '& td:nth-child(2)': {
            display: 'flex',
            justifyContent: 'end',
        },
        '& td:nth-child(3)': {
            display: 'flex',
            justifyContent: 'end',
        },
        '& td:nth-child(4)': {
            display: 'flex',
            justifyContent: 'center'
        },
        '& .MuiTableCell-body': {
            padding: '1px'
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
        },
        '& tr:last-child': {
            borderBottom: 'none'
        }
    },

    tabContainer: {
        height: 'fit-content',
        paddingTop: '17px',
        paddingLeft: '40px',
        paddingBottom: '44px',
        paddingRight: '47px',
        background: Colors.Background,
        display: 'inline-flex',
        minHeight: 'calc(283px - 17px - 44px)',
        minWidth: '100%'
    },
    chartContainerInternal: {
        width: 200,
        position: 'relative',
        marginTop: 22,
        height: 200,
    },
    chart: {
        position: 'absolute',
        top: 0,
    },
    chartPlaceholder: {
        height: 160,
        borderRadius: '50%',
        border: `transparent solid 20px`,
        width: 160
    },
    chartEmptyBackground: {
        borderColor: '#E4EBF3'
    },
    chartLoadingBackground: {
        borderColor: 'transparent',
    },
    sumValue: {
        width: 200,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        height: 'fit-content',
        top: 90,
    },
    sumText: {
        width: 'fit-content',
        height: 'fit-content',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 24,
        color: '#121212'
    },
    sumHeader: {
        width: 200,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        height: 'fit-content',
        top: 66,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.6)'
    },
    loadingLabel: {
        backgroundColor: 'transparent',
        width: 200,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        top: 90,
        left: 0,
        color: Colors.Gray5,
        size: 14,
        fontWeight: 400,
    },
}));