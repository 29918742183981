import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../../..';
import { APIErrorInterface } from '../../../types';
import { AccountFollowMeSettings } from '../../../types/AccountFollowMeSettings';

export interface CallForwardingReducerState {
    accountFollowMeSettings?: AccountFollowMeSettings;

    apiError?: APIErrorInterface;
}

export const initialState: CallForwardingReducerState = {};

export type CallForwardingReducerActions = ActionType<typeof actions>;

export const callForwadingReducer = createReducer<
    CallForwardingReducerState,
    CallForwardingReducerActions
>(initialState)
    .handleAction(actions.getExtensionFollowMeSettings.success, (state, action) => ({
            ...state,
            accountFollowMeSettings: action.payload,
            apiError: undefined
        }))
    .handleAction(actions.getExtensionFollowMeSettings.request, (state) => ({
            ...state,
            apiError: undefined
        }))
    .handleAction(actions.getExtensionFollowMeSettings.failure, (state, action) => ({
            ...state,
            apiError: action.payload
        }))
;

export default callForwadingReducer;
